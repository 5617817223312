import React, { useRef, useState, useEffect } from 'react';
import './App.css';

import * as firebase from 'firebase/app';
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';


import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import { getFirestore, collection,query, orderBy, limit,serverTimestamp, setDoc, addDoc, doc } from "firebase/firestore";

//import 'firebase/firestore';
//import 'firebase/auth';
//import 'firebase/analytics';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const app = firebase.initializeApp({
  apiKey: "AIzaSyCO49u-rvee_4f5QiUn8Mc8Z1NNTYsrZ0k",
  authDomain: "vader-nfl-pickem-chat.firebaseapp.com",
  projectId: "vader-nfl-pickem-chat",
  storageBucket: "vader-nfl-pickem-chat.appspot.com",
  messagingSenderId: "660581977030",
  appId: "1:660581977030:web:c238e9ad23427d2555c0c7",
  measurementId: "G-4MNWR87YZJ"
})

//const auth = firebase.auth();
const auth = getAuth(app);
//const firestore = firebase.firestore();
const firestore = getFirestore(app);
//const analytics = firebase.analytics();


function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="ChatApp">
      <header>
        <h1>⚛️🔥💬</h1>
        <SignOut />
      </header>

      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {

  const signInWithGoogle = () => {
    //const provider = new auth.GoogleAuthProvider();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth,provider).then(function(result) {
      // code which runs on success
      console.log("signInWithGoogle => Success => result : ",result);

    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      console.log("signInWithGoogle => errorCode : ",errorCode);
      //alert(errorCode);
    
      var errorMessage = error.message;
      console.log("signInWithGoogle => errorMessage : ",errorMessage);
  });
  }

  return (
    <>
      <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
      <p>Don't be a dick</p>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}


function ChatRoom() {
  //const btmRef = useRef();
  const dummy = useRef();
  const messagesRef = collection(firestore,'messages');
  
  useEffect(()=>{
    //dummy.current?.scrollIntoView({behavior: 'smooth'});
    let target = document.getElementById("chatWindow");
    setTimeout(()=>{document.getElementById("chatWindow").scroll({
      top: target.offsetTop,
      behavior: 'smooth'
    });},1000);
  },[]) 
  
  //const messagesRef = firestore.collection('messages');
  //const query = messagesRef.orderBy('createdAt').limit(25);
  const fsQuery = query(messagesRef,orderBy('createdAt'),limit(25))
  const [messages] = useCollectionData(fsQuery, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;

    //await messagesRef.add({
      await addDoc(collection(firestore,'messages'),{
    //await setDoc(messagesRef,{
      text: formValue,
      //createdAt: firestore.FieldValue.serverTimestamp(),
      createdAt: serverTimestamp(),
      uid:uid,
      photoURL:photoURL
    })//.then(()=>{
      setFormValue('');
      dummy.current.scrollIntoView({ behavior: 'smooth' });
    //})

    
  }

  return (<>
    <main id="chatWindow">

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>

    <form onSubmit={sendMessage}>

      <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="say something nice" />

      <button type="submit" disabled={!formValue}>🕊️</button>

    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`}>
      <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
      <p>{text}</p>
    </div>
  </>)
}


export default App;