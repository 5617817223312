import React from 'react';

//import bootstrap from 'bootstrap'
// Bootstrap CSS
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import '../App.css';

import { IVaderLeague,IVaderPick,IVaderRole,IVaderUser,IVaderWinLoss,IVaderConfigObj} from '../interfaces/vader'
import { IEspnEventWeek} from '../interfaces/espn'



export interface ITeamsOnByeProps{
  debug:boolean;
  teamsOnBye:IEspnEventWeek | null,
}

export interface ITeamsOnByeState{
  selectedWeek:string|number|null;
}


export default class PickEmApp_TeamsOnBye extends React.Component<ITeamsOnByeProps, ITeamsOnByeState> {
  
  constructor(props: ITeamsOnByeProps, state:ITeamsOnByeState) {
    super(props);
    this.state = {
      selectedWeek:null,
    }
  }
  
  public componentDidMount = async(): Promise<void> => {
    if(this.props.debug){console.log("PickEmApp_TeamsOnBye.componentDidMount() => this : ",this);}
  }


  public render(): React.ReactElement<ITeamsOnByeProps> {
    
    if(this.props.debug){console.log("PickEmApp_TeamsOnBye.render() => this : ",this);}
    return <div>
    {
        this.props.teamsOnBye && this.props.teamsOnBye.teamsOnBye ?
        <div><h2>Teams on Bye this week</h2><br /></div> :
        <h3>No teams on Bye this week</h3>
    }
    
      
    
    {
      this.props.teamsOnBye && this.props.teamsOnBye.teamsOnBye ? this.props.teamsOnBye.teamsOnBye.map((thisTeamOnBye,i)=>{
        return <div key={i} className="row">
        <div className="col-2" />
        <div className="col-4" style={{padding:"20px 0 0 0 "}}>{thisTeamOnBye.displayName}</div>
        <div className="col-4"><img alt="" style={{/*transform:"scale(.25)" */ height:"70px" }} src={thisTeamOnBye.logo} /></div>
        <div className="col-2" />
    </div>
      }) : null }
  </div>
  }

}