import React from 'react';
//import logo from './logo.svg';
import './App.css';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
//import bootstrap from 'bootstrap'
import { Col, Container, Row, Image } from "react-bootstrap";

import { IEspenResponse,IEspnEvent} from './interfaces/espn';
import { IVaderPick,IVaderUser,IVaderWinLoss,IVaderWinLossEvent,IVaderConfigObj} from './interfaces/vader';
import  PickEmApp_TeamsOnBye from './components/byeWeekTeam-component';
import  PickEmApp_ThisEvent  from './components/ThisEvent';
import PickEmApp_MyPicks from './components/myPicksComponents';
import  PickEmApp_TopBar from "./components/TopBar-components";
import {PickEm_Utilities} from "./shared/utilities";
import App from './components/chatComponent/App';

const currentDate = new Date();


export interface IPickEmAppProps{
  debug:boolean;
  thisWeek:number;
  thisYear:number;
  configObj:IVaderConfigObj;
  seasonType:number;
}

export interface IPickEmAppState{
  thisWeeksGames:IEspenResponse | null;
  leaguePicks:IVaderPick[];
  myLeaguesIds:string[];
  selectedLeague:string;
  thisWeekWinsLosses:IVaderWinLoss | null;
  myUser:IVaderUser | null;
  myPicks:IVaderPick[];
  showHideLoginPrompt:boolean;
  showMyPicks:boolean;
  winningPicks:IVaderPick[];
  losingPicks:IVaderPick[];
  tiedPicks:IVaderPick[];
  usersInThisLeague:[];
  userObjectsInThisLeague:IVaderUser[] ;
  teamsVsIds:any[]
}

export default class PickEmApp_Root extends React.Component<IPickEmAppProps, IPickEmAppState> {

  
  constructor(props: IPickEmAppProps, state:IPickEmAppState) {
    super(props);
    this.state = {
      thisWeeksGames:null,
      leaguePicks:[],
      myLeaguesIds:[],
      selectedLeague:"",
      thisWeekWinsLosses:null,
      myUser:null,
      myPicks:[],
      showHideLoginPrompt:false,
      showMyPicks:false,
      winningPicks:[],
      losingPicks:[],
      tiedPicks:[],
      usersInThisLeague:[],
      userObjectsInThisLeague:[],
      teamsVsIds:[]
    }
  }
  
  public componentDidMount = async(): Promise<void> => {

    console.log("PickEmApp_Root.componentDidMount() => this : ", this);
    const doIHaveACookie = PickEm_Utilities.getCookie(this.props.configObj.cookieName);

    setTimeout(async()=>{
      if(doIHaveACookie){
        await this.login_actions(doIHaveACookie);
      }
      else{
        await this.noCookie_start();
      }
    },1000);
    
    setInterval(async()=>{
      if(doIHaveACookie){
        await this.login_actions(doIHaveACookie);
      }
      else{
        await this.noCookie_start();
      }
    },this.props.configObj.refresh_interval_seconds * 1000)
  }

  public currentWeeklyUrl = (seasonType:number):string =>{
    if(seasonType===1){
      return this.props.configObj.preSeasonByWeekUrl ;
    }
    else if(seasonType===3){
      return this.props.configObj.postSeasonByWeekUrl ;
    }
    else{
      return this.props.configObj.regularSeasonByWeekUrl ;
    }
  }

  public insertNewPick = async(userId:number,leagueId:number,teamId:number,eventId:number,pickWeek:number,pickYear:number,seasonType:number,debug:boolean) => {
    if(debug){
      console.log("PickEmApp_Root.insertNewPick() => userId : ",userId);
      console.log("PickEmApp_Root.insertNewPick() => leagueId : ",leagueId);
      console.log("PickEmApp_Root.insertNewPick() => teamId : ",teamId);
      console.log("PickEmApp_Root.insertNewPick() => eventId : ",eventId);
      console.log("PickEmApp_Root.insertNewPick() => pickWeek : ",pickWeek);
      console.log("PickEmApp_Root.insertNewPick() => pickYear : ",pickYear);
      console.log("PickEmApp_Root.insertNewPick() => seasonType : ",seasonType);
    }
    await PickEm_Utilities.newPick(userId,leagueId,teamId,eventId,pickWeek,pickYear,seasonType, debug).then(async()=>{
      await this.postLogin_refresh((this.state.myUser as IVaderUser).email);
    });
  }

  public updateExistingPick = async(existingPickId:number,userId:number,leagueId:number,teamId:number,eventId:number,pickWeek:number,pickYear:number,seasonType:number,debug:boolean) => {
    await PickEm_Utilities.updateMyPick(existingPickId,userId,leagueId,teamId,eventId,pickWeek,pickYear,seasonType, debug).then(async()=>{
      await this.postLogin_refresh((this.state.myUser as IVaderUser).email);
    });
  }

  public processLeaguePicks = async(winLossObject:IVaderWinLoss,leaguePicks:IVaderPick[]) => {
    
    let thisWinningPicks = [];
    let thisLosingPicks = [];
    let thisTiedPicks = [] ;       
    
    if(winLossObject && winLossObject.wins && winLossObject.wins.length && leaguePicks && leaguePicks.length && leaguePicks.length > 0 ){
      

      for(let i = 0 ; i < winLossObject.wins.length ; i++){
        const thisEventId = winLossObject.wins[i].eventId;
        const thisWinningTeamId = winLossObject.wins[i].teamId;
        const winningPicks = leaguePicks.filter((pick)=>{return pick.eventId.toString() == thisEventId && pick.pickTeamId.toString() == thisWinningTeamId})
        if(winningPicks && winningPicks.length && winningPicks.length > 0){
          thisWinningPicks.push(...winningPicks);
        }
      }
      if(this.props.debug){console.log("LeagueReport.processLeaguePicks() => thisWinningPicks : ",thisWinningPicks);}

      for(let i = 0 ; i < winLossObject.losses.length ; i++){
        const thisEventId = winLossObject.losses[i].eventId;
        const thisLosingTeamId = winLossObject.losses[i].teamId;
        const losingPicks = leaguePicks.filter((pick)=>{return pick.eventId.toString() == thisEventId && pick.pickTeamId.toString() == thisLosingTeamId})
        if(losingPicks && losingPicks.length && losingPicks.length > 0){
          thisLosingPicks.push(...losingPicks);
        }
      }

      for(let i = 0 ; i < winLossObject.ties.length ; i++){
        const thisEventId = winLossObject.ties[i].eventId;
        const thisTiedTeamId = winLossObject.ties[i].teamId;
        const tiedPicks = leaguePicks.filter((pick)=>{return pick.eventId.toString() == thisEventId && pick.pickTeamId.toString() == thisTiedTeamId})
        if(tiedPicks && tiedPicks.length && tiedPicks.length > 0){
          thisTiedPicks.push(...tiedPicks);
        }
      }
    }
    else if(leaguePicks && leaguePicks.length && leaguePicks.length > 0 ){

      for(let i = 0 ; i < (winLossObject).wins.length ; i++){
        const thisEventId = (winLossObject).wins[i].eventId;
        const thisWinningTeamId = (winLossObject).wins[i].teamId;
        
        const winningPicks = leaguePicks.filter((pick)=>{return pick.eventId.toString() == thisEventId && pick.pickTeamId.toString() == thisWinningTeamId})
        if(winningPicks && winningPicks.length && winningPicks.length > 0){
          thisWinningPicks.push(...winningPicks);
        }
      }
      if(this.props.debug){console.log("LeagueReport.processLeaguePicks() => thisWinningPicks : ",thisWinningPicks);}

      for(let i = 0 ; i < (winLossObject).losses.length ; i++){
        const thisEventId = (winLossObject).losses[i].eventId;
        const thisLosingTeamId = (winLossObject).losses[i].teamId;
        
        const losingPicks = leaguePicks.filter((pick)=>{return pick.eventId.toString() == thisEventId && pick.pickTeamId.toString() == thisLosingTeamId})
        if(losingPicks && losingPicks.length && losingPicks.length > 0){
          thisLosingPicks.push(...losingPicks);
        }
      }

      for(let i = 0 ; i < winLossObject.ties.length ; i++){
        const thisEventId = winLossObject.ties[i].eventId;
        const thisTiedTeamId = winLossObject.ties[i].teamId;
        
        const tiedPicks = leaguePicks.filter((pick)=>{return pick.eventId.toString() == thisEventId && pick.pickTeamId.toString() == thisTiedTeamId})
        if(tiedPicks && tiedPicks.length && tiedPicks.length > 0){
          thisTiedPicks.push(...tiedPicks);
        }
      }
    }
    
    if(this.props.debug){console.log("LeagueReport.processLeaguePicks()  => thisLosingPicks : ",thisLosingPicks);}
    if(this.props.debug){console.log("LeagueReport.processLeaguePicks()  => thisWinningPicks : ",thisWinningPicks);}      
    if(this.props.debug){console.log("LeagueReport.processLeaguePicks()  => thisTiedPicks : ",thisTiedPicks);}
    
    return [ thisWinningPicks, thisLosingPicks, thisTiedPicks]

  }

  public getThisWeek = async(weekNumber:number) => {
    if(this.state.myUser && this.state.myUser.email ){
      this.postLogin_refresh(this.state.myUser.email);
    }
    else{
      const queryThisWeekResponse = await PickEm_Utilities.queryThisWeek(this.currentWeeklyUrl(this.props.seasonType),weekNumber.toString(),this.props.debug)
      const [thisWeekObj,thisWinLossObj] = this.processThisWeek(queryThisWeekResponse);
  
              
      this.setState({
        thisWeeksGames: thisWeekObj,
        thisWeekWinsLosses: thisWinLossObj,
      });
    }
  }

  public processThisWeek =  (json:any) => {
    let thisWeekWins:IVaderWinLossEvent[] = [];
    let thisWeekLosses:IVaderWinLossEvent[] = [];
    let thisWeekTies:IVaderWinLossEvent[] = [] ;
    
        
         let thisWeeksCompletedGames:IEspnEvent[] = json && json.events ? json.events.filter((event:IEspnEvent)=>{
            return event && 
              event.competitions && 
              event.competitions[0] && 
              event.competitions[0].status.type.name === "STATUS_FINAL" 
                  
          }) : null ;

          if(thisWeeksCompletedGames && thisWeeksCompletedGames.length && thisWeeksCompletedGames.length > 0){
            for(let g = 0 ; g < thisWeeksCompletedGames.length ; g++){
              const thisEventId = thisWeeksCompletedGames[g] && thisWeeksCompletedGames[g].competitions[0] && thisWeeksCompletedGames[g].competitions[0].id ? thisWeeksCompletedGames[g].competitions[0].id : null;
    
              if(thisWeeksCompletedGames[g].competitions[0].competitors[0].winner === false && thisWeeksCompletedGames[g].competitions[0].competitors[1].winner === false){
                let tieTeam1 = thisWeeksCompletedGames[g].competitions[0].competitors[0] ;
                let tieTeam2 = thisWeeksCompletedGames[g].competitions[0].competitors[1] ;
                thisWeekTies.push({
                  eventId:  thisEventId,
                  teamId : tieTeam1 && tieTeam1.id ? tieTeam1.id.toString() : null,
                  teamName : tieTeam1.team && tieTeam1.team.displayName ? tieTeam1.team.displayName : null,
                },{
                  eventId:  thisEventId,
                  teamId : tieTeam2 && tieTeam2.id ? tieTeam2.id.toString() : null,
                  teamName : tieTeam2.team && tieTeam2.team.displayName ? tieTeam2.team.displayName : null,
                });
              }
              else{
                const thisWinningTeam = thisWeeksCompletedGames[g].competitions[0].competitors.filter((competitor)=>{return competitor.winner===true})[0]
                const thisLosingTeam = thisWeeksCompletedGames[g].competitions[0].competitors.filter((competitor)=>{return competitor.winner!==true})[0]
                
                thisWeekLosses.push({
                  eventId: thisEventId,
                  teamId : thisLosingTeam && thisLosingTeam.id ? thisLosingTeam.id.toString() : null,
                  teamName : thisLosingTeam.team && thisLosingTeam.team.displayName ? thisLosingTeam.team.displayName : null,
                });
                thisWeekWins.push({
                  eventId: thisEventId,
                  teamId : thisWinningTeam && thisWinningTeam.id ? thisWinningTeam.id.toString() : null,
                  teamName : thisWinningTeam.team && thisWinningTeam.team.displayName ? thisWinningTeam.team.displayName : null,
                });
              }
            }
          }
        

        const winLossObj = {wins:thisWeekWins,losses:thisWeekLosses,ties:thisWeekTies} ;
        if(this.props.debug){console.log("processThisWeek => winLossObj : ",winLossObj);}
        return [json,winLossObj];
  }



  /*   Life cycle functions  */

  public login_actions = async(email:string)=>{
    PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => this.props.thisWeek",this.props.thisWeek);
    
    const myUserObj = await PickEm_Utilities.queryMyUser(email,this.props.debug);
    PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => myUserObj",myUserObj);

      if(myUserObj && myUserObj.id){
        const selectedWeekCheck = document.getElementById("week") as HTMLSelectElement ;

        //PickEm_Utilities.deleteCookie(this.props.configObj.cookieName);
        PickEm_Utilities.setCookie(this.props.configObj.cookieName, email, this.props.configObj.cookieTimeout);          
        const myLeagueIds = (myUserObj as IVaderUser).leagues.split(" ");//.map(Number) ;
        const myPicksResponse = await PickEm_Utilities.queryMyPicks(parseInt(selectedWeekCheck.value) > 0 ?  parseInt(selectedWeekCheck.value) :this.props.thisWeek,this.props.thisYear, this.props.seasonType,myUserObj.id,this.props.debug) ;
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => myPicksResponse",myPicksResponse);

        const myPicks = myPicksResponse ? Object.values(myPicksResponse) as IVaderPick[]  : [] ;
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => myPicks",myPicks);

        const queryThisWeekResponse = await PickEm_Utilities.queryThisWeek(this.currentWeeklyUrl(this.props.seasonType),(parseInt(selectedWeekCheck.value) > 0 ?  parseInt(selectedWeekCheck.value) : this.props.thisWeek),this.props.debug)
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => queryThisWeekResponse",queryThisWeekResponse);

        const [thisWeekObj,thisWinLossObj] = this.processThisWeek(queryThisWeekResponse);
        
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => thisWeekObj",thisWeekObj);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => thisWinLossObj",thisWinLossObj);


        const leaguePicksResponse = await PickEm_Utilities.queryMyLeaguePicks(parseInt(selectedWeekCheck.value) > 0 ? parseInt(selectedWeekCheck.value) :this.props.thisWeek,this.props.thisYear,this.props.seasonType,this.props.debug,myLeagueIds[0]);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => getMyLeaguePicks  => Response JSON : ", leaguePicksResponse);
        
        const leaguePicks = leaguePicksResponse ? Object.values(leaguePicksResponse) : null ;    
        if(this.props.debug){console.log("getMyLeaguePicks => usableJsonObj : ",leaguePicks);}

        const [thisWinningPicks, thisLosingPicks, thisTiedPicks] = await this.processLeaguePicks(thisWinLossObj,leaguePicks as IVaderPick[]);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => thisLosingPicks",thisLosingPicks);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => thisTiedPicks",thisTiedPicks);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => leaguePicks",leaguePicks);
        
        const usersInThisLeague = leaguePicks && leaguePicks.length && leaguePicks.length > 0 ? leaguePicks.map((a:any)=>{return a.userId}).filter(PickEm_Utilities.onlyUnique) : null ;
        const leagueUsers = await PickEm_Utilities.queryMyLeagueUsers(myLeagueIds[0],this.props.debug);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","login_actions => leagueUsers",leagueUsers);

        const thisWeeksTeamsVsIds = [];

        for(let i = 0 ; i < thisWeekObj.length ; i++){
          thisWeeksTeamsVsIds.push({
              id: thisWeekObj[i].competitors[0].id,
              name: thisWeekObj[i].competitors[0].team.name,
          })
          thisWeeksTeamsVsIds.push({
              id: thisWeekObj[i].competitors[1].id,
              name: thisWeekObj[i].competitors[1].team.name,
          })
        }
        this.setState({
          myPicks : myPicks ? myPicks as IVaderPick[]  : [],
          myUser:myUserObj,
          thisWeeksGames: thisWeekObj,
          thisWeekWinsLosses: thisWinLossObj,
          myLeaguesIds:myLeagueIds,
          winningPicks:thisWinningPicks as IVaderPick[],
          losingPicks:thisLosingPicks as IVaderPick[],
          tiedPicks:thisTiedPicks as IVaderPick[],
          leaguePicks:leaguePicks as IVaderPick[],
          showMyPicks: myPicks ? true : false,
          usersInThisLeague:usersInThisLeague as [],
          userObjectsInThisLeague:leagueUsers as IVaderUser[],
          teamsVsIds:thisWeeksTeamsVsIds
        })
      }      
  }
  
  public postLogin_refresh = async(email:string)=>{
    const selectedWeekString = document.getElementById("week") as HTMLSelectElement ;
    const selectedWeek = parseInt(selectedWeekString.value);
    
    const myUserObj = await PickEm_Utilities.queryMyUser(email,this.props.debug);
    PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => myUserObj",myUserObj);

      if(myUserObj && myUserObj.id){
        const myLeagueIds = (myUserObj as IVaderUser).leagues.split(" ");//.map(Number) ;
        const myPicksResponse = await PickEm_Utilities.queryMyPicks(selectedWeek,this.props.thisYear,this.props.seasonType,myUserObj.id,this.props.debug) ;
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => myPicksResponse",myPicksResponse);

        const myPicks = myPicksResponse ? Object.values(myPicksResponse) as IVaderPick[]  : [] ;
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => myPicks",myPicks);

        const queryThisWeekResponse = await PickEm_Utilities.queryThisWeek(this.currentWeeklyUrl(this.props.seasonType),selectedWeek,this.props.debug)
        const [thisWeekObj,thisWinLossObj] = this.processThisWeek(queryThisWeekResponse);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => queryThisWeekResponse",queryThisWeekResponse);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => thisWeekObj",thisWeekObj);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => thisWinLossObj",thisWinLossObj);



        const leaguePicksResponse = await PickEm_Utilities.queryMyLeaguePicks( selectedWeek, this.props.thisYear,this.props.seasonType, this.props.debug ,this.state.selectedLeague);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => getMyLeaguePicks  => Response JSON : ", leaguePicksResponse);
        
        const leaguePicks = leaguePicksResponse ? Object.values(leaguePicksResponse) : null ;    
        if(this.props.debug){console.log("postPick_refresh => usableJsonObj : ",leaguePicks);}

        const [thisWinningPicks, thisLosingPicks, thisTiedPicks] = await this.processLeaguePicks(thisWinLossObj,leaguePicks as IVaderPick[]);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => thisLosingPicks",thisLosingPicks);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => thisTiedPicks",thisTiedPicks);
        PickEm_Utilities.vaderDebug.appLifecycle("App.js","postPick_refresh => leaguePicks",leaguePicks);
        
        
        this.setState({
          myPicks : myPicks ? myPicks as IVaderPick[]  : [],
          myUser:myUserObj,
          thisWeeksGames: thisWeekObj,
          thisWeekWinsLosses: thisWinLossObj,
          myLeaguesIds:myLeagueIds,
          selectedLeague:myLeagueIds[0],
          winningPicks:thisWinningPicks as IVaderPick[],
          losingPicks:thisLosingPicks as IVaderPick[],
          tiedPicks:thisTiedPicks as IVaderPick[],
          leaguePicks:leaguePicks as IVaderPick[],
          showMyPicks: myPicks ? true : false
        }) 
      }      
    //});
  }

  public noCookie_start = async() => {
    const currentWeekDDV = document.querySelector("#week") ;
    const currentSelectedWeek =  currentWeekDDV && (currentWeekDDV as HTMLSelectElement).value ? (currentWeekDDV as HTMLSelectElement).value : null ;
    const isTheCurrentWeekSelected = parseInt(currentSelectedWeek as string) == this.props.thisWeek ? true : false ;
    if(isTheCurrentWeekSelected && this.props.thisWeek && this.props.thisYear){
      const queryThisWeekResponse = await PickEm_Utilities.queryThisWeek(this.currentWeeklyUrl(this.props.seasonType),(this.props.thisWeek),this.props.debug)
      const [thisWeekObj,thisWinLossObj] = this.processThisWeek(queryThisWeekResponse);
      
      this.setState({
        thisWeeksGames: thisWeekObj,
        thisWeekWinsLosses: thisWinLossObj,
      });
    }
  }

  public refreshData = async() => {
    const currentWeekDDV = document.querySelector("#week") ;

    const currentSelectedWeek =  currentWeekDDV && (currentWeekDDV as HTMLSelectElement).value ? (currentWeekDDV as HTMLSelectElement).value : null ;
    const isTheCurrentWeekSelected = parseInt(currentSelectedWeek as string) == this.props.thisWeek ? true : false ;
    if(isTheCurrentWeekSelected && this.props.thisWeek ){
      const queryThisWeekResponse = await PickEm_Utilities.queryThisWeek(this.currentWeeklyUrl(this.props.seasonType),(this.props.thisWeek),this.props.debug)
      const [thisWeekObj,thisWinLossObj] = this.processThisWeek(queryThisWeekResponse);
      
      this.setState({
        thisWeeksGames: thisWeekObj,
        thisWeekWinsLosses: thisWinLossObj,
      }); 
    }
  }


  public render(): React.ReactElement<IPickEmAppProps> {
    PickEm_Utilities.vaderDebug.appLifecycle("App.tsx","render() => this - ",this);

    return (<div className="App">
      <Container>
        <br />
        <PickEmApp_TopBar
          debug={this.props.debug}
          thisWeekNumber={this.props.seasonType===3 ? this.props.thisWeek /*+ this.props.configObj.numberOfRegularSeasonWeeksThisYear*/ : this.props.thisWeek}
          login_actions={this.login_actions}
          getThisWeek={this.getThisWeek}
          
          myUser={this.state.myUser}
          setShowHideLoginPrompt={this.setShowHideLoginPrompt}
          showHideLoginPrompt={this.state.showHideLoginPrompt}
          configObjCookieName={this.props.configObj.cookieName}

          setSelectedLeague={this.setSelectedLeague}
          selectedLeague={this.state.selectedLeague}
          myLeaguesIds={this.state.myLeaguesIds}
          seasonType={this.props.seasonType}
          
        />
        <br />
        <br />

          {
            !this.state.showMyPicks && this.state.thisWeeksGames ? 
            <Row>
                <Col xs={12} lg={8}>
                {
                  this.state.thisWeeksGames && this.state.thisWeeksGames.events ? this.state.thisWeeksGames.events.map((thisGame,i)=>{
                    console.log("PickEmApp_Root.render() => thisGame : ", thisGame);    
                    return  <PickEmApp_ThisEvent
                            thisEvent={thisGame ? thisGame : null}
                            debug={this.props.debug}
                    />
                  }) 
                  : null
                }
              </Col>
              <Col xs={12} lg={4} offset={8}>
                <PickEmApp_TeamsOnBye
                  debug={this.props.debug}
                  teamsOnBye={this.state.thisWeeksGames && this.state.thisWeeksGames.week ? this.state.thisWeeksGames.week : null}
                />

              </Col>
            </Row> 
            
             : this.state.thisWeeksGames ? <Row>
                <Col xs={12} lg={8}>
                { 
                  this.state.myLeaguesIds && this.state.userObjectsInThisLeague && this.state.thisWeeksGames && this.state.myUser && this.state.myPicks && this.state.thisWeeksGames.events ? this.state.thisWeeksGames.events.map((thisEvent,i)=>{
                    return <PickEmApp_MyPicks 
                        thisEvent={ thisEvent }
                        myUser={(this.state.myUser ? this.state.myUser : null) as IVaderUser }
                        myPicks={ this.state.myPicks }
                        debug={this.props.debug}
                        updateExistingPick={this.updateExistingPick}
                        insertNewPick={this.insertNewPick}
                        leagueId={this.state.myLeaguesIds && this.state.myLeaguesIds.length && this.state.myLeaguesIds.length === 1 ? this.state.myLeaguesIds[0] : this.state.myLeaguesIds && this.state.myLeaguesIds.length > 1 ? this.state.myLeaguesIds[0] : "0" } 
                        myLeagueUsers={this.state.userObjectsInThisLeague && this.state.userObjectsInThisLeague.length && this.state.userObjectsInThisLeague.length > 0 ? this.state.userObjectsInThisLeague : [] }
                        leagueUserPicks={this.state.leaguePicks}
                    />
                  }) : null
                }
                </Col>
                <Col xs={12} lg={4} offset={8}>
                  <Row>
                    <PickEmApp_TeamsOnBye
                      debug={this.props.debug}
                      teamsOnBye={this.state.thisWeeksGames && this.state.thisWeeksGames.week ? this.state.thisWeeksGames.week : null}
                    />
                  </Row>
                  <div className={"section"} />
                  <Row>
                    {
                      document.getElementById("week") && (document.getElementById("week") as HTMLSelectElement).value && parseInt((document.getElementById("week") as HTMLSelectElement).value) && parseInt((document.getElementById("week") as HTMLSelectElement).value) <= this.props.thisWeek ?

                        <div>
                          <br />
                              <h4>Week League Report</h4>                                

                            {
                              this.state.tiedPicks && this.state.tiedPicks.length && this.state.tiedPicks.length > 0 ? 
                              <Row>
                                <Col xs={2}></Col>
                                <Col xs={2}>Wins</Col>
                                <Col xs={2}>Losses</Col>
                                <Col xs={2}>Ties</Col>
                                <Col xs={2}></Col>
                              </Row> : 
                              <Row>
                                <Col xs={2}></Col>
                                <Col xs={2}>Wins</Col>
                                <Col xs={2}></Col>
                                <Col xs={2}>Losses</Col>
                                <Col xs={2}></Col>
                              </Row>
                            }
                            
                            {
                              this.state.tiedPicks &&
                              this.state.tiedPicks.length && 
                              this.state.tiedPicks.length > 0 && 
                              this.state.usersInThisLeague && 
                              this.state.userObjectsInThisLeague && 
                              this.state.usersInThisLeague.length && 
                              this.state.userObjectsInThisLeague.length && 
                              this.state.usersInThisLeague.length > 0 && 
                              this.state.userObjectsInThisLeague.length > 0 ? this.state.usersInThisLeague.map((thisUserId,i)=>{
                                  //const thisUserObj = this.state.leaguePicks && this.state.leaguePicks.length && this.state.leaguePicks.length > 0 ? this.state.leaguePicks.filter((pick)=>{return pick.userId == thisUserId})[0] : null;
                                  const thisUserObj = this.state.leaguePicks && this.state.leaguePicks.length && this.state.leaguePicks.length > 0 ? this.state.userObjectsInThisLeague.filter((userObj)=>{return userObj.id == thisUserId})[0] : null;
                                  const thisUserWins = this.state.winningPicks && this.state.winningPicks.length && this.state.winningPicks.length > 0 ? this.state.winningPicks.filter((pick)=>{return pick.userId == thisUserId}) : null;
                                  const thisUserLosses = this.state.losingPicks && this.state.losingPicks.length && this.state.losingPicks.length > 0 ? this.state.losingPicks.filter((pick)=>{return pick.userId == thisUserId}) : null;
                                  const thisUserTies:IVaderPick[] = (this.state.tiedPicks as any).filter((pick:IVaderPick)=>{return pick.userId == thisUserId}) ;
                                  if(this.props.debug){console.log("LeagueReport => thisUserObj : ",thisUserObj);}
                                  if(this.props.debug){console.log("LeagueReport => thisUserWins : ",thisUserWins);}
                                  if(this.props.debug){console.log("LeagueReport => thisUserLosses : ",thisUserLosses);}
                                  if(this.props.debug){console.log("LeagueReport => thisUserTies : ",thisUserTies);}

                                  return(<Row key={i}>
                                    <Col xs={2}>{ thisUserObj && thisUserObj.icon ? <Image className={"image"} height={"64px"} roundedCircle src={`data:image/png;base64,${thisUserObj.icon}`} /> : null} </Col> 
                                    <Col xs={2}>{thisUserWins ? thisUserWins.length : 0}</Col>
                                    <Col xs={2}>{ thisUserLosses ? thisUserLosses.length : 0 }</Col>
                                    <Col xs={2}>{ thisUserTies ? thisUserTies.length : 0 }</Col>
                                    <Col xs={2}></Col>
                                    
                                  </Row>);
                              }) : 
                              
                              this.state.userObjectsInThisLeague && this.state.usersInThisLeague && this.state.usersInThisLeague.length && this.state.userObjectsInThisLeague.length && this.state.usersInThisLeague.length > 0 && this.state.userObjectsInThisLeague.length > 0  ? this.state.usersInThisLeague.map((thisUserId,i)=>{
                                //const thisUserObj = this.state.leaguePicks && this.state.leaguePicks.length && this.state.leaguePicks.length > 0 ? this.state.leaguePicks.filter((pick)=>{return pick.userId == thisUserId})[0] : null;
                                const thisUserObj = this.state.leaguePicks && this.state.leaguePicks.length && this.state.leaguePicks.length > 0 ? this.state.userObjectsInThisLeague.filter((userObj)=>{return userObj.id == thisUserId})[0] : null;
                                const thisUserWins = this.state.winningPicks && this.state.winningPicks.length && this.state.winningPicks.length > 0 ? this.state.winningPicks.filter((pick)=>{return pick.userId == thisUserId}) : null;
                                const thisUserLosses = this.state.losingPicks && this.state.losingPicks.length && this.state.losingPicks.length > 0 ? this.state.losingPicks.filter((pick)=>{return pick.userId == thisUserId}) : null;
                                if(this.props.debug){console.log("LeagueReport => thisUserObj : ",thisUserObj);}
                                if(this.props.debug){console.log("LeagueReport => thisUserWins : ",thisUserWins);}
                                if(this.props.debug){console.log("LeagueReport => thisUserLosses : ",thisUserLosses);}
                                

                                return(<Row className="leagueReportUser" key={i}>
                                  <Col xs={2}>{ thisUserObj && thisUserObj.icon ? 
                                    <Row>
                                      <Row>
                                        <Col>
                                          <Image className={"image"} style={{height:"64px"}} roundedCircle src={`data:image/png;base64,${thisUserObj.icon}`} />   
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>{thisUserObj.name}</Col>
                                      </Row>
                                    </Row>
                                    : 
                                    null} 
                                  </Col> 
                                  <Col className="leageReport_Score" xs={2}>{thisUserWins ? thisUserWins.length : 0}</Col>
                                  <Col xs={2}></Col>
                                  <Col className="leageReport_Score" xs={2}>{ thisUserLosses ? thisUserLosses.length : 0 }</Col>
                                  <Col xs={2}></Col>
                                </Row>);
                                }) 
                                :<div>No picks for this week</div>
                              }
                          </div> : null 
                        }

                        <div className={"section"} />

                          <Row>
                            <h4>Notes :</h4>
                            <Col>
               
                              <ul style={{fontSize: "small"}}>
                                <li className="text-center">Game in progress : Teams' primary color-background under white text</li>
                                <li className="notes losingComponent text-center">Previous game, team lost : Pink background</li>
                                <li className="notes winningComponent text-center">Previous game, team won : Light green background</li>
                                <li className="notes unplayedComponent text-center">Game in the future, team not picked : White background</li>
                                <li className="notes myPick unplayedComponenttext-center">Game in the future that you picked to win : blue'ish background with green border</li>
                                <li>Pick database is backed up nightly to the "cloud" with versioning enabled</li>
                                <li>Every pick is assigned a Created & Modified date + IP address of the Pick'er</li>
                                <li>All source data originates from the <a href="https://gist.github.com/akeaswaran/b48b02f1c94f873c6655e7129910fc3b">ESPN API</a></li>
                              </ul>
                              
                            </Col>
                          </Row>
                          <div className={"section"} />
                          {
                            this.state.myUser ? 
                            <Row>
                              <Col xs={12}>
                                <h4>League Chat</h4>
                                <br />
                                <App />
                              </Col>                
                            </Row> : null
                          }
                  </Row>
                  
                </Col>
              </Row> : null
          }

      
      </Container>
  </div>);

  }

  public setSelectedLeague = (leagueId:string) => {    
    this.setState({
      selectedLeague:leagueId
    });
  }

  public setShowHideLoginPrompt = (value:boolean) => {
    this.setState({
      showHideLoginPrompt:value
    });
  }
}
