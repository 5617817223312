import React from 'react';

// Bootstrap CSS
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import '../App.css';
//import { useState,useEffect } from 'react'
import { Container, Col, Row, Image } from "react-bootstrap";
//import { useEffect,useCallback } from "react";
import { IVaderUser} from '../interfaces/vader'
import {PickEm_Utilities} from "../shared/utilities";

export interface ITopBarProps{
  debug:boolean;
  thisWeekNumber:number;
  getThisWeek:any;
  login_actions:any;
  myUser:IVaderUser|null;
  setShowHideLoginPrompt:any;
  showHideLoginPrompt:boolean;
  configObjCookieName:string;
  setSelectedLeague:any;
  selectedLeague:string;
  myLeaguesIds:string[]|null;
  seasonType:number;
  //isPostSeason:boolean;
}

export interface ITopBarState{
  selectedWeek:string|number|null;
}
export default class PickEmApp_TopBar extends React.Component<ITopBarProps, ITopBarState> {

  
  constructor(props: ITopBarProps, state:ITopBarState) {
    super(props);
    this.state = {
      selectedWeek:null,
    }
  }
  
  public componentDidMount = async(): Promise<void> => {
    await setTimeout(()=>{
      
      if(this.props.debug){console.log('PickEmApp_TopBar.componentDidMount() => this : ',this);}
      
      const currentWeekDDVal = document.getElementById("week") as HTMLSelectElement
      
      if(currentWeekDDVal && currentWeekDDVal.value && currentWeekDDVal.value === '0'){
        (document.getElementById("week")  as HTMLSelectElement).value=this.props.thisWeekNumber.toString();
        
        //Added for Post Season work-a-round
        //(document.getElementById("week")  as HTMLSelectElement).value="4";
        this.weekDropdownHandler();
      }
    },500);
  }

  public weekDropdownHandler = async(): Promise<void>  => {
    const currentWeekDDVal = document.getElementById("week") as HTMLSelectElement ;
    //if(currentWeekDDVal && currentWeekDDVal.value && !this.props.isPostSeason){
      await this.props.getThisWeek(parseInt(currentWeekDDVal.value) && parseInt(currentWeekDDVal.value) > 0 ? parseInt(currentWeekDDVal.value) : 1);
    //}
  }

  public dropdownComponent = (props:{seasonType:number}) => {
    if(props.seasonType===1){
      return(
        <select 
          disabled={true}
          style={{width:"200px"}} 
          onChange={this.weekDropdownHandler}            
          name="Week" id="week">
            <option key={0}  selected disabled hidden value="0">0</option>
            <option key={24} value="1">Pre Season 1</option>
            <option key={25} value="2">Pre Season 2</option>
            <option key={26} value="3">Pre Season 3</option>
            <option key={27} selected value="4">Pre Season 4</option>
          </select> 
        );
    }
    else if(props.seasonType===3){
      return(
        <select 
          disabled={true}
          style={{width:"200px"}} 
          onChange={this.weekDropdownHandler}            
          name="Week" id="week">
            <option key={0} selected disabled hidden value="0">0</option>
            <option key={19} value="1">Post Season 1 (Wild Card)</option>
            <option key={20} value="2">Post Season 2 (Divisional)</option>
            <option key={21} value="3">Post Season 3 (Conference)</option>
            <option key={22} value="4">Post Season 4 (Pro Bowl)</option>
            <option key={23} value="5">Post Season 5 (SupurB owl)</option>
          </select> 
        );
    }
    else{
      return(
        <select 
          disabled={true}
          style={{width:"200px"}} 
          onChange={this.weekDropdownHandler}            
          name="Week" id="week">
            <option key={0} selected disabled hidden value="0">0</option>
            <option key={1} value="1">Week 1</option>
            <option key={2} value="2">Week 2</option>
            <option key={3} value="3">Week 3</option>
            <option key={4} value="4">Week 4</option>
            <option key={5} value="5">Week 5</option>
            <option key={6} value="6">Week 6</option>
            <option key={7} value="7">Week 7</option>
            <option key={8} value="8">Week 8</option>
            <option key={9} value="9">Week 9</option>
            <option key={10} value="10">Week 10</option>
            <option key={11} value="11">Week 11</option>
            <option key={12} value="12">Week 12</option>
            <option key={13} value="13">Week 13</option>
            <option key={14} value="14">Week 14</option>
            <option key={15} value="15">Week 15</option>
            <option key={16} value="16">Week 16</option>
            <option key={17} value="17">Week 17</option>
            <option key={18} value="18">Week 18</option>
        </select> 
        );
    }
  }

  public render(): React.ReactElement<ITopBarProps> {
    if(this.props.debug){console.log("PickEmApp_TopBar.render() => this : ",this);}
    return (<div className="App">
    <Container>
      {
        <Row className={"d-md-none,d-lg-block"}>

          {navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) ?  <div>On your phone, you should view this page in landscape</div> : null} 
        
        </Row>
      }
    <Row>
      <Row>
        <Col ></Col>
        <Col>
            {
              this.props.thisWeekNumber ? <h2>Welcome to week { this.props.thisWeekNumber}</h2> : null
            }
        </Col>
        <Col>
          { this.props.myLeaguesIds && this.props.myLeaguesIds.length && this.props.myLeaguesIds.length > 0 &&  this.props.myUser ? null : <Col><input type="button" value="Show login dialog" onClick={()=>{this.props.setShowHideLoginPrompt(true)}}/></Col> }
        </Col>
      </Row>
      
        <br />
      <Row>
        <Col xs={8}>
          <Row>
            <Col xs={6} lg={6}><label style={{textDecoration: "line-through"}}>To view previous or future weeks, select a week number from the dropdown : </label></Col>
            <Col xs={6} lg={3}>
              <this.dropdownComponent seasonType={this.props.seasonType} />
            </Col>
          </Row>
          
        </Col>
        

        <Col xs={12} lg={6} >
            {
              this.props.myUser ? 
                <Row>
                  <Col>
                    <Row><Col><h4>Hi {this.props.myUser.name}</h4></Col></Row>
                    {this.props.myLeaguesIds && this.props.myLeaguesIds.length > 1 ?
                    
                      <Row>
                        <Col>
                          <label id={"TopBar_myLeague_lbl"}>My Leagues</label>
                          <select name="LeagueDD" id="leagueDD" onChange={()=>{this.props.setSelectedLeague( parseInt((document.getElementById("leagueDD") as HTMLSelectElement ).value) ) }} >
                            {
                              this.props.myLeaguesIds ? this.props.myLeaguesIds.map((leagueId)=>{
                                return <option key={leagueId} value={leagueId}>{leagueId}</option>
                              }) : null
                            }
                          </select>
                        </Col>
                      </Row> : null
                    
                    }
                    
                  </Col>
                  <Col><Image className={"image"} style={{height:"70px"}} roundedCircle src={`data:image/png;base64,${this.props.myUser.icon}`} /></Col>
                  <Col>
                    <input 
                      type="button" 
                      value="Logout" 
                      onClick={()=>{
                        PickEm_Utilities.deleteCookie(this.props.configObjCookieName); 
                        window.location.reload();
                      }}
                    />
                  </Col>
                </Row> : null                
            }
            
            {
              this.props.showHideLoginPrompt ? 
              <Row style={{margin:"5px"}}>
                <Col xs={12} className={'loginBoxes'}>
                  <input style={{background:"grey !important"}} type="text" id="loginName" />
                </Col>
                <Col xs={12} className={'loginBoxes'}>
                  <input id="loginNameBtn" style={{background:"grey !important"}} type="button" value="login now_" onClick={async()=>{await this.props.login_actions((document.getElementById("loginName") as any).value,this.props.thisWeekNumber );this.props.setShowHideLoginPrompt(false)}}/>
                </Col>
              </Row> : <span></span>
              
            }
            
        </Col>
      </Row>
    </Row>
    </Container>
    </div>
    )
  }
}


