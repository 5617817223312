import React from 'react';
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Col, Image, Row } from "react-bootstrap";


import '../App.css';
import { IEspnCompetitor, IEspnEvent, IEspnEventStatus } from '../interfaces/espn';

export interface IThisEventProps{
    debug: boolean;
    thisEvent:IEspnEvent | null;
}


export interface IThisEventState{
    selectedWeek:string|number|null;
}

  export default class PickEmApp_ThisEvent extends React.Component<IThisEventProps, IThisEventState> {
  
    
    constructor(props: IThisEventProps, state:IThisEventState) {
      super(props);
      this.state = {
        selectedWeek:null,
      }
    }
    
    public componentDidMount = async(): Promise<void> => {
        
        if(this.props.debug){console.log('PickEmApp_ThisEvent.componentDidMount() => this : ',this);}

    }
  
    public render(): React.ReactElement<IThisEventProps> {
        if(this.props.debug){console.log("PickEmApp_ThisEvent.render() => this : ",this);}

        
        const thisEventCompetitors = this.props.thisEvent && this.props.thisEvent.competitions &&  this.props.thisEvent.competitions[0] && this.props.thisEvent.competitions[0].competitors[0] && this.props.thisEvent.competitions[0].competitors[0].team ? this.props.thisEvent.competitions[0].competitors : null;
        if(this.props.debug){console.log("PickEmApp_ThisEvent.render() => thisEventCompetitors : ",thisEventCompetitors);}

        const hasThisGameBeenPlayed = this.props.thisEvent 
                                        && this.props.thisEvent.competitions 
                                        && this.props.thisEvent.competitions[0] 
                                        && this.props.thisEvent.competitions[0].status 
                                        && this.props.thisEvent.competitions[0].status.type 
                                        && this.props.thisEvent.competitions[0].status.type.completed 
                                        ? true : false ; 
        const isThisGameInProgress = this.props.thisEvent 
        && this.props.thisEvent.status 
        && this.props.thisEvent.status.type 
        && this.props.thisEvent.status.type.name 
        && ( this.props.thisEvent.status.type.name === "STATUS_IN_PROGRESS" || this.props.thisEvent.status.type.name === "STATUS_HALFTIME" || this.props.thisEvent.status.type.name === "STATUS_END_PERIOD" )
        ? true : false ; 
        


        const homeTeamObj = thisEventCompetitors && thisEventCompetitors[0] && thisEventCompetitors[0].homeAway && thisEventCompetitors[0].homeAway === "home" ? thisEventCompetitors[0] : (thisEventCompetitors && thisEventCompetitors[1] ? thisEventCompetitors[1] : null)  ;
        const homeTeamLogoUrl = homeTeamObj && homeTeamObj.team && homeTeamObj.team.logo ? homeTeamObj.team.logo : null
        const homeTeamScore = homeTeamObj && homeTeamObj.score ? homeTeamObj.score : null ;
        const homeTeamName = homeTeamObj && homeTeamObj.team && homeTeamObj.team.name ? homeTeamObj.team.name : null ;
        const homeTeamOverallRecord = homeTeamObj && homeTeamObj.records && homeTeamObj.records.length > 0 ? homeTeamObj.records.filter((record)=>{return record.name==="overall"}) : null ;
        const homeTeamRecord = homeTeamOverallRecord && homeTeamOverallRecord[0] && homeTeamOverallRecord[0].summary ? homeTeamOverallRecord[0].summary : null ;
        

                            
        const awayTeamObj = thisEventCompetitors && thisEventCompetitors[1] && thisEventCompetitors[1].homeAway && thisEventCompetitors[1].homeAway === "away" ? thisEventCompetitors[1] : (thisEventCompetitors && thisEventCompetitors[0] ? thisEventCompetitors[0] : null)  ;
        const awayTeamLogoUrl = awayTeamObj && awayTeamObj.team && awayTeamObj.team.logo ? awayTeamObj.team.logo : null ;
        const awayTeamScore = awayTeamObj && awayTeamObj.score ? awayTeamObj.score : null ;
        const awayTeamName = awayTeamObj && awayTeamObj.team && awayTeamObj.team.name ? awayTeamObj.team.name : null ;
        const awayTeamOverallRecord = awayTeamObj && awayTeamObj.records && awayTeamObj.records.length > 0 ? awayTeamObj.records.filter((record)=>{return record.name==="overall"}) : null ;
        const awayTeamRecord = awayTeamOverallRecord && awayTeamOverallRecord[0] && awayTeamOverallRecord[0].summary ? awayTeamOverallRecord[0].summary : null ;

        


        return (<Container>
            <Row>
                <Col>
                    <this.awayTeamCard
                        logoUrl={ awayTeamLogoUrl }
                        score={ awayTeamScore }
                        teamName={ awayTeamName }
                        teamRecord={ awayTeamRecord }
                        inProgress={ isThisGameInProgress }
                        winner={ awayTeamObj && awayTeamObj.winner ? awayTeamObj.winner : false }
                        gamePlayed={ hasThisGameBeenPlayed }
                        
                    />
                </Col>
                <Col xs={1} className={"vs"} >
                    <Col>VS</Col>
                    {
                        (this.props.thisEvent as IEspnEvent ).status && (this.props.thisEvent as IEspnEvent ).status.type && (this.props.thisEvent as IEspnEvent ).status.type.name && (this.props.thisEvent as IEspnEvent ).status.type.name === "STATUS_HALFTIME" ? 
                        <Col className={"competitionStatus"} xs={12}>HALFTIME</Col> : 
                        <Col className={"competitionDate"} xs={12}>{ (this.props.thisEvent as IEspnEvent ).status.type.shortDetail}</Col>
                    }
                </Col>
                <Col>
                    <this.homeTeamCard
                        logoUrl={ homeTeamLogoUrl }
                        score={ homeTeamScore }
                        teamName={ homeTeamName }
                        teamRecord={ homeTeamRecord }
                        inProgress={ isThisGameInProgress }
                        winner={ homeTeamObj && homeTeamObj.winner ? homeTeamObj.winner : false  }
                        gamePlayed={ hasThisGameBeenPlayed }
                    />
                </Col>
                
            </Row>
    </Container>);


  }


    public homeTeamCard = (
        props:{
            logoUrl:string | null,
            score?:string | null,
            teamName:string | null,
            teamRecord:string | null,
            inProgress:boolean,
            winner:boolean,
            gamePlayed:boolean,
        }) => {

        return <Container className={`${ props.gamePlayed ? (props.winner ? "winningComponent" : "losingComponent") : ( props.inProgress ? "inProgress" : "unplayedComponent") }`}>
        <Row>    
            <Col xs={9}>
                <Row>
                    <Col>{(props.gamePlayed || props.inProgress) && props.score ? props.score : ""}</Col>
                    <Col>{props.teamName ? props.teamName : "" }</Col>
                    <Col>({props.teamRecord ? props.teamRecord : ""})</Col>
                </Row>
            </Col>
            <Col xs={3}><Image className={"image"} fluid={true} style={{ height:"70px" }} src={props.logoUrl ? props.logoUrl : ""} /></Col>
        </Row>
    </Container>
    }

    public awayTeamCard = (
        props:{
            logoUrl:string | null,
            score?:string | null,
            teamName:string | null,
            teamRecord:string | null,
            inProgress:boolean,
            winner:boolean,
            gamePlayed:boolean,

        }) => {

            return <Container className={`${ props.gamePlayed ? (props.winner ? "winningComponent" : "losingComponent") : ( props.inProgress ? "inProgress" : "unplayedComponent") }`}>
            <Row>
                <Col xs={3}><Image className={"image"} fluid={true} style={{ height:"70px" }} src={props.logoUrl ? props.logoUrl : ""} /></Col>
                <Col xs={9}>
                    <Row>
                        <Col>({props.teamRecord ? props.teamRecord : ""})</Col>
                        <Col>{props.teamName ? props.teamName : "" }</Col>
                        <Col>{(props.gamePlayed || props.inProgress) && props.score ? props.score : ""}</Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }

  }


    /*
    public PlayedEventComponent = (props:{ competitors:IEspnCompetitor[], statusObj:IEspnEventStatus })=>{
        if(this.props.debug){console.log("PickEmApp_ThisEvent.PlayedEventComponent() => props : ",props);}

        const hasThisGameBeenPlayed = props.statusObj 
        && props.statusObj.type
        && props.statusObj.type.completed 
        ? true : false ; 

        const isThisGameInProgress = props.statusObj 
        && props.statusObj 
        && props.statusObj.type 
        && props.statusObj.type.name 
        && ( props.statusObj.type.name === "STATUS_IN_PROGRESS" || props.statusObj.type.name === "STATUS_HALFTIME" || props.statusObj.type.name === "STATUS_END_PERIOD" )
        ? true : false ;  

        const homeTeamObj = props.competitors[0].homeAway === "home" ? props.competitors[0] : props.competitors[1]  ;
        const homeTeamLogoUrl = homeTeamObj && homeTeamObj.team && homeTeamObj.team.logo ? homeTeamObj.team.logo : null
        const homeTeamScore = homeTeamObj && homeTeamObj.score ? homeTeamObj.score : null ;
        const homeTeamName = homeTeamObj && homeTeamObj.team && homeTeamObj.team.name ? homeTeamObj.team.name : null ;
        const homeTeamOverallRecord = homeTeamObj && homeTeamObj.records && homeTeamObj.records.length > 0 ? homeTeamObj.records.filter((record)=>{return record.name==="overall"}) : null ;
        const homeTeamRecord = homeTeamOverallRecord && homeTeamOverallRecord[0] && homeTeamOverallRecord[0].summary ? homeTeamOverallRecord[0].summary : null ;
        

        const awayTeamObj = props.competitors[1].homeAway === "away" ? props.competitors[1] : props.competitors[0]  ;
        const awayTeamLogoUrl = awayTeamObj && awayTeamObj.team && awayTeamObj.team.logo ? awayTeamObj.team.logo : null ;
        const awayTeamScore = awayTeamObj && awayTeamObj.score ? awayTeamObj.score : null ;
        const awayTeamName = awayTeamObj && awayTeamObj.team && awayTeamObj.team.name ? awayTeamObj.team.name : null ;
        const awayTeamOverallRecord = awayTeamObj && awayTeamObj.records && awayTeamObj.records.length > 0 ? awayTeamObj.records.filter((record)=>{return record.name==="overall"}) : null ;
        const awayTeamRecord = awayTeamOverallRecord && awayTeamOverallRecord[0] && awayTeamOverallRecord[0].summary ? awayTeamOverallRecord[0].summary : null ;

        console.log(" PLAYEd: home ",homeTeamObj);

        return (<Container>
                <Row>
                    <Col>
                        <this.awayTeamCard
                            logoUrl={ awayTeamLogoUrl }
                            score={ awayTeamScore }
                            teamName={ awayTeamName }
                            teamRecord={ awayTeamRecord }
                            inProgress={ isThisGameInProgress }
                            winner={ awayTeamObj && awayTeamObj.winner ? awayTeamObj.winner : false }
                            gamePlayed={ hasThisGameBeenPlayed }
                            
                        />
                    </Col>
                    <Col xs={1} style={{padding:"20px 0 0 0 "}}> VS</Col>
                    <Col>
                        <this.homeTeamCard
                            logoUrl={ homeTeamLogoUrl }
                            score={ homeTeamScore }
                            teamName={ homeTeamName }
                            teamRecord={ homeTeamRecord }
                            inProgress={ isThisGameInProgress }
                            winner={ homeTeamObj && homeTeamObj.winner ? homeTeamObj.winner : false  }
                            gamePlayed={ hasThisGameBeenPlayed }
                        />
                    </Col>
                    
                </Row>
        </Container>)
            {/*<Row>
                <Col className={` ${awayTeamObj && awayTeamObj.winner ? "winningComponent" : "losingComponent"}`}>
                    <this.LeftPlayedTeamComponent 
                        winnerBool={awayTeamObj && awayTeamObj.winner ? true : false}
                        teamObject={awayTeamObj}
                        leftSideBool={true}
                    />
                </Col>
                
                <Col xs={1} style={{padding:"20px 0 0 0 "}}> VS</Col>

                <Col className={ homeTeamObj && homeTeamObj.winner ? "winningComponent" : "losingComponent"}>
                    <this.RightPlayedTeamComponent 
                        winnerBool={homeTeamObj && homeTeamObj.winner ? true : false}
                        teamObject={homeTeamObj}
                        
                    />
                </Col>
            </Row>*//*}
            
    }


    public LeftPlayedTeamComponent = (props:{winnerBool:boolean,teamObject:IEspnCompetitor,leftSideBool:boolean}) => {
        const thisTeamsDisplayName = props.teamObject && props.teamObject.team && props.teamObject.team.displayName ? props.teamObject.team.displayName : null ;
        const thisTeamsRecord = props.teamObject && props.teamObject.records && props.teamObject.records.length > 2 ? props.teamObject.records.filter((record)=>{return record.type==='total'}) : null ;
        //thisTeamsRecord = thisTeamsRecord && thisTeamsRecord.length > 0 ? thisTeamsRecord : teamObject && teamObject.records ? teamObject.records.filter((record)=>{return record.name==='YTD'}) : null ;
        console.log(" PLAYEd: thisTeamsRecord ",thisTeamsRecord);
        

            return <Row style={{ lineHeight:"10px"}}>
                <Col xs={0} sm={4} className={`d-none d-sm-inline ${props.winnerBool ? "winningTeam" : "losingTeam"} `} ><img alt="" style={{height:"70px"}} className="img-fluid" src={props.teamObject && props.teamObject.team && props.teamObject.team.logo ? props.teamObject.team.logo : ""} /></Col>
                <Col xs={12} sm={6} style={{maxHeight:"10px !important" }} className={`${props.winnerBool ? "winningTeam" : "losingTeam"} textPadding`}> ( { thisTeamsRecord && thisTeamsRecord[0] && thisTeamsRecord[0].summary && thisTeamsRecord[0].summary ? thisTeamsRecord[0].summary : "" } ) -  {  thisTeamsDisplayName ? thisTeamsDisplayName : "" }</Col>
                <Col xs={12} sm={2} style={{maxHeight:"10px !important", }} className={`${props.winnerBool ? "winningTeam" : "losingTeam"} textPadding`}> { props.teamObject && props.teamObject.score ? props.teamObject.score : ""} </Col>            
            </Row>
        
    }

    public RightPlayedTeamComponent = (props:{winnerBool:boolean,teamObject:IEspnCompetitor}) => {
        const thisTeamsDisplayName = props.teamObject && props.teamObject.team && props.teamObject.team.displayName ? props.teamObject.team.displayName : null ;
        const thisTeamsRecord = props.teamObject && props.teamObject.records && props.teamObject.records.length > 2 ? props.teamObject.records.filter((record)=>{return record.type==='total'}) : null ;

        return <Row style={{ lineHeight:"10px"}}>
            <Col xs={12} sm={2} className={`${props.winnerBool ? "winningTeam" : "losingTeam"} textPadding order-2  order-sm-1`}> { props.teamObject && props.teamObject.score ? props.teamObject.score : ""} </Col>
            <Col xs={12} sm={6} className={`${props.winnerBool ? "winningTeam" : "losingTeam"} textPadding order-1  order-sm-2`}> {  thisTeamsDisplayName ? thisTeamsDisplayName : "" } - ( { thisTeamsRecord && thisTeamsRecord[0] && thisTeamsRecord[0].summary && thisTeamsRecord[0].summary ? thisTeamsRecord[0].summary : "" } ) </Col>
        
            <Col xs={0} sm={4} className={`d-none d-sm-inline ${props.winnerBool ? "winningTeam" : "losingTeam"}  order-sm-3`}><img alt=""  style={{height:"70px"}} className="img-fluid" src={props.teamObject && props.teamObject.team && props.teamObject.team.logo ? props.teamObject.team.logo : ""} /></Col>
        </Row>
    }

//The component below is the parent of the above two components - Game is over
    /*public PlayedEventComponent = (props:{ competitors:IEspnCompetitor[] })=>{

        const homeTeamObj = props.competitors[0].homeAway === "home" ? props.competitors[0] : props.competitors[1]  ;
        const awayTeamObj = props.competitors[1].homeAway === "away" ? props.competitors[1] : props.competitors[0]  ;
        console.log(" PLAYEd: home ",homeTeamObj);

        return (
            <Row>
                <Col className={` ${awayTeamObj && awayTeamObj.winner ? "winningComponent" : "losingComponent"}`}>
                    <this.LeftPlayedTeamComponent 
                        winnerBool={awayTeamObj && awayTeamObj.winner ? true : false}
                        teamObject={awayTeamObj}
                        leftSideBool={true}
                    />
                </Col>
                
                <Col xs={1} style={{padding:"20px 0 0 0 "}}> VS</Col>

                <Col className={ homeTeamObj && homeTeamObj.winner ? "winningComponent" : "losingComponent"}>
                    <this.RightPlayedTeamComponent 
                        winnerBool={homeTeamObj && homeTeamObj.winner ? true : false}
                        teamObject={homeTeamObj}
                        
                    />
                </Col>
            </Row>)
    }


    public UnPlayedTeamComponent = (props:{teamObject:IEspnCompetitor,leftSideBool:boolean})=>{
        const thisTeamsDisplayName = props.teamObject && props.teamObject.team && props.teamObject.team.displayName ? props.teamObject.team.displayName : null ;
        const thisTeamsRecord = props.teamObject && props.teamObject.records && props.teamObject.records.length > 2 ? props.teamObject.records.filter((record)=>{return record.name==='All Splits'}) : null ;

        if(props.leftSideBool){
            return <Row> 
                <Col xs={4} sm={4}><Image fluid={true} style={{ height:"70px" }} src={props.teamObject && props.teamObject.team && props.teamObject.team.logo ? props.teamObject.team.logo : ""} /></Col>
                <Col xs={8} sm={8}  className="textPadding">( { thisTeamsRecord && thisTeamsRecord[0] && thisTeamsRecord[0].summary && thisTeamsRecord[0].summary ? thisTeamsRecord[0].summary : "" } ) -  {  thisTeamsDisplayName ? thisTeamsDisplayName : "" }</Col>
            </Row> 
        }
        else{
            return <Row> 
                <Col xs={8} sm={8}  className="textPadding"> {  thisTeamsDisplayName ? thisTeamsDisplayName : "" } - ( { thisTeamsRecord && thisTeamsRecord[0] && thisTeamsRecord[0].summary && thisTeamsRecord[0].summary ? thisTeamsRecord[0].summary : "" } )</Col>
                <Col xs={4} sm={4}><Image fluid={true} style={{ height:"70px" }} src={props.teamObject && props.teamObject.team && props.teamObject.team.logo ? props.teamObject.team.logo : ""} /></Col>
            </Row>
        }  
        
    }
//The component below is the parent of the components above - Kickoff is in the future
    public UnPlayedEventComponent = (props:{ competitors:IEspnCompetitor[] })=>{
        const homeTeamObj = props.competitors[0].homeAway === "home" ? props.competitors[0] : props.competitors[1]  ;
        const awayTeamObj = props.competitors[1].homeAway === "away" ? props.competitors[1] : props.competitors[0]  ;

        return (
            <Row>
                <Col className={"unplayedComponent"}>
                    <this.UnPlayedTeamComponent teamObject = { awayTeamObj } leftSideBool={true} />
                </Col>
                
                <Col xs={1} style={{padding:"20px 0 0 0 "}}> VS</Col>
                
                <Col className={"unplayedComponent"}>
                    <this.UnPlayedTeamComponent teamObject = { homeTeamObj }  leftSideBool={false} />
                </Col>
            </Row>)
    }


    public CurrentlyPlayingTeamComponent = (props:{teamObject:IEspnCompetitor,leftSideBool:boolean})=>{
        const thisTeamsDisplayName = props.teamObject && props.teamObject.team && props.teamObject.team.displayName ? props.teamObject.team.displayName : null ;
        const thisTeamsRecord = props.teamObject && props.teamObject.records && props.teamObject.records.length > 2 ? props.teamObject.records.filter((record)=>{return record.name==='All Splits'}) : null ;

        if(props.leftSideBool){
            return <Row> 
                <Col xs={12} className="leftScoreTextPadding order-2  order-sm-1"> { props.teamObject && props.teamObject.score ? props.teamObject.score : "" }</Col>
                <Col xs={4} sm={4}><Image fluid={true} style={{ height:"70px" }} src={props.teamObject && props.teamObject.team && props.teamObject.team.logo ? props.teamObject.team.logo : ""} /></Col>
                <Col xs={8} sm={8}   className="textPadding">( { thisTeamsRecord && thisTeamsRecord[0] && thisTeamsRecord[0].summary && thisTeamsRecord[0].summary ? thisTeamsRecord[0].summary : "" } ) -  {  thisTeamsDisplayName ? thisTeamsDisplayName : "" }</Col>
                
            </Row> 
        }
        else{
            return <Row> 
                
                <Col xs={8} sm={8}   className="textPadding"> {  thisTeamsDisplayName ? thisTeamsDisplayName : "" } - ( { thisTeamsRecord && thisTeamsRecord[0] && thisTeamsRecord[0].summary && thisTeamsRecord[0].summary ? thisTeamsRecord[0].summary : "" } )</Col>
                <Col xs={4} sm={4}><Image fluid={true} style={{ height:"70px" }} src={props.teamObject && props.teamObject.team && props.teamObject.team.logo ? props.teamObject.team.logo : ""} /></Col>
                <Col xs={12} className="rightScoreTextPadding"> { props.teamObject && props.teamObject.score ? props.teamObject.score : "" }</Col>
            </Row>
        }  
        
    }
//The component below is the parent of the components above - The game is currently in progress
    public CurrentlyPlayingEventComponent = (props:{ thisEventCompetitors:IEspnCompetitor[], statusObj:IEspnEventStatus })=>{
        
        const homeTeamObj = props.thisEventCompetitors[0].homeAway === "home" ? props.thisEventCompetitors[0] : props.thisEventCompetitors[1]  ;
        const awayTeamObj = props.thisEventCompetitors[1].homeAway === "away" ? props.thisEventCompetitors[1] : props.thisEventCompetitors[0]  ;

        /*let returnPeriodString ;
        if(statusObj && statusObj.period){
            if(statusObj.period === 1){
                returnPeriodString = "1st" ;
            }
            else if(2){
                returnPeriodString = "2nd" ;
            }
            else if(3){
                returnPeriodString = "3rd" ;
            }
            else if(4){
                returnPeriodString = "4th" ;
            }
            else {
                returnPeriodString = "something else" ;
            }
        }
        return (
            <Row>
                <Col>
                    <this.CurrentlyPlayingTeamComponent teamObject = { awayTeamObj } leftSideBool={true} />
                </Col>
                
                <Col xs={1} style={{padding:"20px 0 0 0 "}}>
                    {
                        props.statusObj && props.statusObj.type && props.statusObj.type.name && props.statusObj.type.name === "STATUS_HALFTIME" ? 
                        <Col xs={12} className="halftime">HALFTIME</Col> : 
                        <Col xs={12}>{ props.statusObj.type.detail}</Col>
                    }
                </Col>
                
                <Col>
                    <this.CurrentlyPlayingTeamComponent teamObject = { homeTeamObj }  leftSideBool={false} />
                </Col>
            </Row>)
    }

  }
    /*
export const ThisEvent = (props:{
    debug:boolean,
    thisEvent:IEspnEvent | null,
}) => {
    
    if(props.debug){console.log("This EVENT : ",props.thisEvent);}
    const thisEventCompetitors = props.thisEvent && props.thisEvent.competitions &&  props.thisEvent.competitions[0] && props.thisEvent.competitions[0].competitors[0] && props.thisEvent.competitions[0].competitors[0].team ? props.thisEvent.competitions[0].competitors : null;
    const hasThisGameBeenPlayed = props.thisEvent 
                                    && props.thisEvent.competitions 
                                    && props.thisEvent.competitions[0] 
                                    && props.thisEvent.competitions[0].status 
                                    && props.thisEvent.competitions[0].status.type 
                                    && props.thisEvent.competitions[0].status.type.completed 
                                    ? true : false ; 
    const isThisGameInProgress = props.thisEvent 
    && props.thisEvent.status 
    && props.thisEvent.status.type 
    && props.thisEvent.status.type.name 
    && ( props.thisEvent.status.type.name === "STATUS_IN_PROGRESS" || props.thisEvent.status.type.name === "STATUS_HALFTIME" || props.thisEvent.status.type.name === "STATUS_END_PERIOD" )
    ? true : false ;  

        if(hasThisGameBeenPlayed){
            return <PlayedEventComponent 
            competitors={thisEventCompetitors as IEspnCompetitor[]}
            />
        }
        else if(isThisGameInProgress){
            return <CurrentlyPlayingEventComponent thisEventCompetitors={thisEventCompetitors as IEspnCompetitor[]} statusObj={(props.thisEvent as IEspnEvent).status} />
        }
        else{
            return <UnPlayedEventComponent competitors={thisEventCompetitors as IEspnCompetitor[]} />;
        }

}*/