
import React from 'react';
// Bootstrap CSS
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Col, Image, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
//import {PickEm_Utilities} from "../shared/utilities" ;

import { IEspnEvent,IEspnCompetitor} from '../interfaces/espn' ;
import { IVaderPick,IVaderUser} from '../interfaces/vader' ;
import  PickEmApp_MyPicks_IconRow  from './myPicks_IconRowComponent' ;
import '../App.css';

export interface IMyPicksProps{
    debug:boolean;
    thisEvent:IEspnEvent;
    myUser:IVaderUser;
    myPicks:IVaderPick[];

    updateExistingPick:any;
    insertNewPick:any;
    leagueId:string;
    myLeagueUsers:IVaderUser[];
    leagueUserPicks:IVaderPick[];

  }
  
  export interface IMyPicksState{
    selectedWeek:string|number|null;
  }
  
export default class PickEmApp_MyPicks extends React.Component<IMyPicksProps, IMyPicksState> {
    
    constructor(props: IMyPicksProps, state:IMyPicksState) {
      super(props);
      this.state = {
        selectedWeek:null,
      }
    }
    
    public componentDidMount = async(): Promise<void> => {
        if(this.props.debug){console.log("PickEmApp_MyPicks.componentDidMount() => this : ",this);}

    }

  
    public render(): React.ReactElement<IMyPicksProps> {

        if(this.props.debug){console.log("PickEmApp_MyPicks.render() => thisEvent : ",this.props.thisEvent);}
        if(this.props.debug){console.log("PickEmApp_MyPicks.render() => myPicks : ",this.props.myPicks);}

        if(this.props.debug){console.log("PickEmApp_MyPicks.render() => this : ",this);}

    
        const thisEventCompetitors = this.props.thisEvent && this.props.thisEvent.competitions &&  this.props.thisEvent.competitions[0] && this.props.thisEvent.competitions[0].competitors[0] && this.props.thisEvent.competitions[0].competitors[0].team ? this.props.thisEvent.competitions[0].competitors : null;
        if(this.props.debug){console.log("PickEmApp_MyPicks.render() => thisEventCompetitors : ",thisEventCompetitors);}

        const hasThisGameBeenPlayed = this.props.thisEvent 
                                        && this.props.thisEvent.competitions 
                                        && this.props.thisEvent.competitions[0] 
                                        && this.props.thisEvent.competitions[0].status 
                                        && this.props.thisEvent.competitions[0].status.type 
                                        && this.props.thisEvent.competitions[0].status.type.completed 
                                        ? true : false ; 
        const isThisGameInProgress = this.props.thisEvent 
        && this.props.thisEvent.status 
        && this.props.thisEvent.status.type 
        && this.props.thisEvent.status.type.name 
        && ( this.props.thisEvent.status.type.name === "STATUS_IN_PROGRESS" || this.props.thisEvent.status.type.name === "STATUS_HALFTIME" || this.props.thisEvent.status.type.name === "STATUS_END_PERIOD" )
        ? true : false ; 
        
        const eventId = this.props.thisEvent && this.props.thisEvent.id ? this.props.thisEvent.id : null ;
        const eventDate = this.props.thisEvent && this.props.thisEvent.date ? this.props.thisEvent.date : null ;
        const pickWeek = this.props.thisEvent && this.props.thisEvent.week && this.props.thisEvent.week.number ? this.props.thisEvent.week.number : null
        const pickYear =  this.props.thisEvent && this.props.thisEvent.season && this.props.thisEvent.season.year ? this.props.thisEvent.season.year : null
        const seasonType =  this.props.thisEvent && this.props.thisEvent.season && this.props.thisEvent.season.type ? this.props.thisEvent.season.type : null

        const homeTeamObj = thisEventCompetitors && thisEventCompetitors[0] && thisEventCompetitors[0].homeAway && thisEventCompetitors[0].homeAway === "home" ? thisEventCompetitors[0] : (thisEventCompetitors && thisEventCompetitors[1] ? thisEventCompetitors[1] : null)  ;
        if(this.props.debug){console.log("PickEmApp_MyPicks.render() => homeTeamObj : ",homeTeamObj);}

        const homeTeamLogoUrl = homeTeamObj && homeTeamObj.team && homeTeamObj.team.logo ? homeTeamObj.team.logo : null
        const homeTeamScore = homeTeamObj && homeTeamObj.score ? homeTeamObj.score : null ;
        const homeTeamName = homeTeamObj && homeTeamObj.team && homeTeamObj.team.name ? homeTeamObj.team.name : null ;
        const homeTeamOverallRecord = homeTeamObj && homeTeamObj.records && homeTeamObj.records.length > 0 ? homeTeamObj.records.filter((record)=>{return record.name==="overall"}) : null ;
        const homeTeamRecord = homeTeamOverallRecord && homeTeamOverallRecord[0] && homeTeamOverallRecord[0].summary ? homeTeamOverallRecord[0].summary : null ;
        const homeTeamPick = this.props.myPicks && this.props.myPicks.length && this.props.myPicks.length > 0 && homeTeamObj && homeTeamObj.id? this.props.myPicks.filter((myPick)=>{return myPick.pickTeamId == parseInt(homeTeamObj.id) })[0] : null ;
        const homeTeamAltColor = homeTeamObj && homeTeamObj.team && homeTeamObj.team.alternateColor ? homeTeamObj.team.alternateColor : null
        const homeTeamPrimaryColor = homeTeamObj && homeTeamObj.team && homeTeamObj.team.color ? homeTeamObj.team.color : null 
        
                            
        const awayTeamObj = thisEventCompetitors && thisEventCompetitors[1] && thisEventCompetitors[1].homeAway && thisEventCompetitors[1].homeAway === "away" ? thisEventCompetitors[1] : (thisEventCompetitors && thisEventCompetitors[0] ? thisEventCompetitors[0] : null)  ;
        if(this.props.debug){console.log("PickEmApp_MyPicks.render() => awayTeamObj : ",awayTeamObj);}

        const awayTeamLogoUrl = awayTeamObj && awayTeamObj.team && awayTeamObj.team.logo ? awayTeamObj.team.logo : null ;
        const awayTeamScore = awayTeamObj && awayTeamObj.score ? awayTeamObj.score : null ;
        const awayTeamName = awayTeamObj && awayTeamObj.team && awayTeamObj.team.name ? awayTeamObj.team.name : null ;
        const awayTeamOverallRecord = awayTeamObj && awayTeamObj.records && awayTeamObj.records.length > 0 ? awayTeamObj.records.filter((record)=>{return record.name==="overall"}) : null ;
        const awayTeamRecord = awayTeamOverallRecord && awayTeamOverallRecord[0] && awayTeamOverallRecord[0].summary ? awayTeamOverallRecord[0].summary : null ;
        const awayTeamPick = this.props.myPicks && this.props.myPicks.length && this.props.myPicks.length > 0 && awayTeamObj && awayTeamObj.id ? this.props.myPicks.filter((myPick)=>{return myPick.pickTeamId == parseInt(awayTeamObj.id) })[0] : null ;
        const awayTeamAltColor = awayTeamObj && awayTeamObj.team && awayTeamObj.team.alternateColor ? awayTeamObj.team.alternateColor : null ;
        const awayTeamPrimaryColor = awayTeamObj && awayTeamObj.team && awayTeamObj.team.color ? awayTeamObj.team.color : null ;

        
        const homeTeamPicks_LeagueUserPicks = this.props.leagueUserPicks && this.props.leagueUserPicks.length && this.props.leagueUserPicks.length > 0 ? this.props.leagueUserPicks.filter((thisLeaguePick)=>{return thisLeaguePick.pickTeamId === parseInt((homeTeamObj as IEspnCompetitor).id.toString()) }) : null
        const awayTeamPicks_LeagueUserPicks = this.props.leagueUserPicks && this.props.leagueUserPicks.length && this.props.leagueUserPicks.length > 0 ? this.props.leagueUserPicks.filter((thisLeaguePick)=>{return thisLeaguePick.pickTeamId === parseInt((awayTeamObj as IEspnCompetitor).id.toString() ) }) : null
        if(this.props.debug){console.log("PickEmApp_MyPicks.render() => homeTeamPicks_LeagueUserPicks : ",homeTeamPicks_LeagueUserPicks);}

        const pickForThisGame = homeTeamPick ? homeTeamPick : awayTeamPick ;

        return (<Container>
                {
                    this.props.debug ?
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props)=>{
                                return <Tooltip {...props} >
                                    { ` Event ID : ${eventId} ` }
                                    { ` Event Date : ${eventDate} ` }
                                    
                                </Tooltip>
                            }}
                        >
                         
                    <Row>
                        <Col> 
                            <this.awayTeamCard
                                logoUrl={ awayTeamLogoUrl }
                                score={ awayTeamScore }
                                teamName={ awayTeamName }
                                teamRecord={ awayTeamRecord }
                                inProgress={ isThisGameInProgress }
                                winner={ awayTeamObj && awayTeamObj.winner ? awayTeamObj.winner : false }
                                gamePlayed={ hasThisGameBeenPlayed }
                                teamPick={ awayTeamPick }
                                eventId={parseInt(eventId as string)}
                                pickWeek={pickWeek}
                                pickYear={pickYear}
                                userId={this.props.myUser.id}
                                leagueId={this.props.leagueId}
                                teamId={awayTeamObj && awayTeamObj.id ? parseInt(awayTeamObj.id) : null } 
                                pickForThisGame={pickForThisGame}
                                altColor={awayTeamAltColor as string}
                                //primaryColor={PickEm_Utilities.shadeHexColor(`#${awayTeamPrimaryColor as string}`,.8)}
                                primaryColor={`#${awayTeamPrimaryColor as string}`}
                                teamPicks_LeagueUserIds={awayTeamPicks_LeagueUserPicks ? awayTeamPicks_LeagueUserPicks as IVaderPick[] : null }
                                seasonType={seasonType}
                            />
                        </Col>
                        <Col xs={1} className={"vs"}>
                            <Col>VS</Col>
                            {
                                (this.props.thisEvent as IEspnEvent ).status && (this.props.thisEvent as IEspnEvent ).status.type && (this.props.thisEvent as IEspnEvent ).status.type.name && (this.props.thisEvent as IEspnEvent ).status.type.name === "STATUS_HALFTIME" ? 
                                <Col className={"competitionStatus"} xs={12} >HALFTIME</Col> : 
                                <Col className={"competitionDate"} xs={12}>{ (this.props.thisEvent as IEspnEvent ).status.type.shortDetail }</Col>
                            }
                        </Col>
                        <Col>
                            <this.homeTeamCard
                                logoUrl={ homeTeamLogoUrl }
                                score={ homeTeamScore }
                                teamName={ homeTeamName }
                                teamRecord={ homeTeamRecord }
                                inProgress={ isThisGameInProgress }
                                winner={ homeTeamObj && homeTeamObj.winner ? homeTeamObj.winner : false  }
                                gamePlayed={ hasThisGameBeenPlayed }
                                teamPick={ homeTeamPick }
                                eventId={parseInt(eventId as string)}
                                pickWeek={pickWeek}
                                pickYear={pickYear}
                                userId={this.props.myUser.id}
                                leagueId={this.props.leagueId}
                                teamId={homeTeamObj && homeTeamObj.id ? parseInt(homeTeamObj.id) : null }
                                pickForThisGame={pickForThisGame}
                                //primaryColor={PickEm_Utilities.shadeHexColor(`#${homeTeamPrimaryColor as string}`,.7)}
                                primaryColor={`#${homeTeamPrimaryColor as string}`}
                                altColor={homeTeamAltColor as string}
                                teamPicks_LeagueUserIds={homeTeamPicks_LeagueUserPicks ? homeTeamPicks_LeagueUserPicks as IVaderPick[] : null}
                                seasonType={seasonType}
                            />
                        </Col>
                        
                    </Row>
                </OverlayTrigger> : 
                <Row>
                <Col> 
                    <this.awayTeamCard
                        logoUrl={ awayTeamLogoUrl }
                        score={ awayTeamScore }
                        teamName={ awayTeamName }
                        teamRecord={ awayTeamRecord }
                        inProgress={ isThisGameInProgress }
                        winner={ awayTeamObj && awayTeamObj.winner ? awayTeamObj.winner : false }
                        gamePlayed={ hasThisGameBeenPlayed }
                        teamPick={ awayTeamPick }
                        eventId={parseInt(eventId as string)}
                        pickWeek={pickWeek}
                        pickYear={pickYear}
                        userId={this.props.myUser.id}
                        leagueId={this.props.leagueId}
                        teamId={awayTeamObj && awayTeamObj.id ? parseInt(awayTeamObj.id) : null } 
                        pickForThisGame={pickForThisGame}
                        altColor={awayTeamAltColor as string}
                        //primaryColor={PickEm_Utilities.shadeHexColor(`#${awayTeamPrimaryColor as string}`,.8)}
                        primaryColor={`#${awayTeamPrimaryColor as string}`}
                        teamPicks_LeagueUserIds={awayTeamPicks_LeagueUserPicks ? awayTeamPicks_LeagueUserPicks as IVaderPick[] : null }
                        seasonType={seasonType}
                    />
                </Col>
                <Col xs={1} className={"vs"}>
                    <Col>VS</Col>
                    {
                        (this.props.thisEvent as IEspnEvent ).status && (this.props.thisEvent as IEspnEvent ).status.type && (this.props.thisEvent as IEspnEvent ).status.type.name && (this.props.thisEvent as IEspnEvent ).status.type.name === "STATUS_HALFTIME" ? 
                        <Col className={"competitionStatus"} xs={12} >HALFTIME</Col> : 
                        <Col className={"competitionDate"} xs={12}>{ (this.props.thisEvent as IEspnEvent ).status.type.shortDetail }</Col>
                    }
                </Col>
                <Col>
                    <this.homeTeamCard
                        logoUrl={ homeTeamLogoUrl }
                        score={ homeTeamScore }
                        teamName={ homeTeamName }
                        teamRecord={ homeTeamRecord }
                        inProgress={ isThisGameInProgress }
                        winner={ homeTeamObj && homeTeamObj.winner ? homeTeamObj.winner : false  }
                        gamePlayed={ hasThisGameBeenPlayed }
                        teamPick={ homeTeamPick }
                        eventId={parseInt(eventId as string)}
                        pickWeek={pickWeek}
                        pickYear={pickYear}
                        userId={this.props.myUser.id}
                        leagueId={this.props.leagueId}
                        teamId={homeTeamObj && homeTeamObj.id ? parseInt(homeTeamObj.id) : null }
                        pickForThisGame={pickForThisGame}
                        //primaryColor={PickEm_Utilities.shadeHexColor(`#${homeTeamPrimaryColor as string}`,.7)}
                        primaryColor={`#${homeTeamPrimaryColor as string}`}
                        altColor={homeTeamAltColor as string}
                        teamPicks_LeagueUserIds={homeTeamPicks_LeagueUserPicks ? homeTeamPicks_LeagueUserPicks as IVaderPick[] : null}
                        seasonType={seasonType}
                    />
                </Col>
                
            </Row>
        }
        </Container>);
    }

    

        
    public homeTeamCard = (
        props:{
            logoUrl:string | null,
            score?:string | null,
            teamName:string | null,
            teamRecord:string | null,
            inProgress:boolean,
            winner:boolean,
            gamePlayed:boolean,
            teamPick:IVaderPick | null,
            userId:number,
            leagueId:string,
            teamId:number | null,
            eventId:number,
            pickWeek:number | null,
            pickYear:number | null,
            pickForThisGame:IVaderPick | null,
            altColor:string;
            primaryColor:string;
            teamPicks_LeagueUserIds:IVaderPick[] | null;
            seasonType:number | null;
        }) => {
            if(this.props.debug){console.log("PickEmApp_MyPicks.homeTeamCard() => props : ",props);}
            const thisTeamId = props.teamId ;
            return <Container 
                    style={{ backgroundColor: props && props.inProgress ? props.primaryColor : "white" }} 
                    className={`
                        ${ props && props.teamPick  &&  props.teamPick.id ? "myPick" : "my"} 
                        ${ props && props.gamePlayed ? 
                            (props.winner ? 
                                "winningComponent" : 
                                "losingComponent"
                            ) : 
                            ( props && props.inProgress ? 
                                "inProgress" : 
                                "unplayedComponent"
                            ) 
                        }
                        ${
                            props && props.teamPick && props.teamPick.id && props.gamePlayed ?
                                (props.winner ?
                                    "pickWon" : 
                                    "pickLost" 
                                )
                            : null 
                        }
                    `}
                    onClick={async()=>{

                        if(props && props.inProgress === false && props.gamePlayed === false){
                            if(props && props.teamId && props.pickWeek && props.pickYear ){
                                if(props && props.pickForThisGame && props.pickForThisGame.id){
                                    //await PickEm_Utilities.updateMyPick(props.pickForThisGame.id,props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,this.props.debug)
                                    this.props.updateExistingPick(props.pickForThisGame.id,props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,props.seasonType,this.props.debug);
                                }else {
                                    //await PickEm_Utilities.newPick(props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,this.props.debug);
                                    this.props.insertNewPick(props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,props.seasonType,this.props.debug);
                                }
                            }
                        }
                    }} >
            <Row /*className={`${ props.gamePlayed ? (props.winner ? "winningComponent" : "losingComponent") : ( props.inProgress ? "inProgress" : "unplayedComponent") }`}*/ > 
                <Col xs={9}>
                    <Row>
                        <Col className={"teamScore"}>{props && (props.gamePlayed || props.inProgress) && props.score ? props.score : ""}</Col>
                        
                        
                        {
                                this.props.debug ?
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props)=>{
                                        return <Tooltip {...props} >
                                            { ` Team ID : ${thisTeamId} ` }
                                        </Tooltip>
                                    }}
                                >
                                    <Col className={"teamName"}>{props.teamName ? props.teamName : "" }</Col>
                                </OverlayTrigger> : 
                                
                                <Col className={"teamName"}>{props.teamName ? props.teamName : "" }</Col>
                            }


                        <Col className={"teamRecord"}>({props && props.teamRecord ? props.teamRecord : ""})</Col>
                    </Row>
                    <Row>
                        {
                            props && (props.gamePlayed || props.inProgress) && props.teamPicks_LeagueUserIds && props.teamPicks_LeagueUserIds.length && props.teamPicks_LeagueUserIds.length > 0 ? 
                                <PickEmApp_MyPicks_IconRow
                                    thisTeamsPickers_leagueUsers={props.teamPicks_LeagueUserIds}
                                    debug={this.props.debug}
                                    myLeagueUsers={this.props.myLeagueUsers} 
                                /> : null
                        }
                    </Row>
                    
                </Col>
                <Col xs={3}><Image fluid={true} className={"image"} style={{ height:"70px" }} src={props && props.logoUrl ? props.logoUrl : ""} /></Col>
            </Row>
        </Container>
    }

    public awayTeamCard = (
        props:{
            logoUrl:string | null,
            score?:string | null,
            teamName:string | null,
            teamRecord:string | null,
            inProgress:boolean,
            winner:boolean,
            gamePlayed:boolean,
            teamPick:IVaderPick | null,
            userId:number,
            leagueId:string,
            teamId:number | null,
            eventId:number,
            pickWeek:number | null,
            pickYear:number | null,
            pickForThisGame:IVaderPick | null,
            altColor:string;
            primaryColor:string;
            teamPicks_LeagueUserIds:IVaderPick[] | null;
            seasonType:number | null;

        }) => {
            if(this.props.debug){console.log("PickEmApp_MyPicks.awayTeamCard() => props : ",props);}
            const thisTeamId = props.teamId ;

            return <Container 
                        style={{ backgroundColor: props.inProgress ? props.primaryColor : "white"  }} 
                        //className={`${props.teamPick && props.teamPick.id ? "myPick" : "my"} ${ props.gamePlayed ? (props.winner ? "winningComponent" : "losingComponent") : ( props.inProgress ? "inProgress" : "unplayedComponent") }`} 
                        className={`
                        ${ props && props.teamPick  &&  props.teamPick.id ? "myPick" : "my"} 
                        ${ props && props.gamePlayed ? 
                            (props.winner ? 
                                "winningComponent" : 
                                "losingComponent"
                            ) : 
                            ( props && props.inProgress ? 
                                "inProgress" : 
                                "unplayedComponent"
                            ) 
                        }
                        ${
                            props && props.teamPick && props.teamPick.id && props.gamePlayed ?
                                (props.winner ?
                                    "pickWon" : 
                                    "pickLost" 
                                )
                            : null 
                        }
                    `}
                        onClick={async()=>{
            
                if(props.inProgress === false && props.gamePlayed === false){
                    if(props.teamId && props.pickWeek && props.pickYear ){
                        if(props.pickForThisGame && props.pickForThisGame.id){
                            //await PickEm_Utilities.updateMyPick(props.pickForThisGame.id,props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,this.props.debug)
                            await this.props.updateExistingPick(props.pickForThisGame.id,props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,props.seasonType,this.props.debug);
                        }else {
                            //await PickEm_Utilities.newPick(props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,this.props.debug);
                            await this.props.insertNewPick(props.userId,props.leagueId,props.teamId,props.eventId,props.pickWeek,props.pickYear,props.seasonType,this.props.debug);
                        }
                    }
                }                
            }
                 } >
            <Row>
                <Col xs={3}><Image className={"image"} fluid={true} style={{ height:"70px" }} src={props.logoUrl ? props.logoUrl : ""} /></Col>
                <Col xs={9}>
                    <Row>
                        <Col className={"teamRecord"}>({props.teamRecord ? props.teamRecord : ""})</Col>
                        
                        {
                            this.props.debug ?
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props)=>{
                                    return <Tooltip {...props} >
                                        { ` Team ID : ${thisTeamId} ` }
                                    </Tooltip>
                                }}
                            >
                                <Col className={"teamName"}>{props.teamName ? props.teamName : "" }</Col>
                            </OverlayTrigger> : 
                            
                            <Col className={"teamName"}>{props.teamName ? props.teamName : "" }</Col>
                        }
                        
                        
                        
                        
                        <Col className={"teamScore"}>{(props.gamePlayed || props.inProgress) && props.score ? props.score : ""}</Col>
                    </Row>
                    <Row>
                    {
                        props && (props.gamePlayed || props.inProgress) && props.teamPicks_LeagueUserIds && props.teamPicks_LeagueUserIds.length && props.teamPicks_LeagueUserIds.length > 0 ? 
                            <PickEmApp_MyPicks_IconRow
                                thisTeamsPickers_leagueUsers={props.teamPicks_LeagueUserIds}
                                debug={this.props.debug}
                                myLeagueUsers={this.props.myLeagueUsers} 
                            /> : null
                    }
                </Row>
                </Col>
            </Row>
        </Container>
    }


}


