
import React from 'react';
// Bootstrap CSS
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Col, Image, Row,OverlayTrigger , Tooltip } from "react-bootstrap";
import {PickEm_Utilities} from "../shared/utilities" ;

import { IEspenResponse,IEspnCompetition,IEspnEvent,IEspnEventStatus,IEspnCompetitor,IEspnTeam} from '../interfaces/espn' ;
import { IVaderLeague,IVaderPick,IVaderRole,IVaderUser,IVaderWinLoss,IVaderWinLossEvent,IVaderConfigObj} from '../interfaces/vader' ;

import '../App.css';

export interface IMyPicksIconRowProps{
    debug:boolean;
    //thisEvent:IEspnEvent;
    //myUser:IVaderUser;
    //myPicks:IVaderPick[];
    //updateExistingPick:any;
    //insertNewPick:any;
    //leagueId:number;
    myLeagueUsers:IVaderUser[];
    thisTeamsPickers_leagueUsers:IVaderPick[];
  }
  
  export interface IMyPicksIconRowState{
    selectedWeek:string|number|null;
  }
  
export default class PickEmApp_MyPicks_IconRow extends React.Component<IMyPicksIconRowProps, IMyPicksIconRowState> {
    
    constructor(props: IMyPicksIconRowProps, state:IMyPicksIconRowState) {
      super(props);
      this.state = {
        selectedWeek:null,
      }
    }
    
    public componentDidMount = async(): Promise<void> => {
        //if(this.props.debug){console.log("PickEmApp_MyPicks_IconRow.componentDidMount() => this : ",this);}

    }

    public render(): React.ReactElement<IMyPicksIconRowProps> {
        //if(this.props.debug){console.log("PickEmApp_MyPicks_IconRow.teamsLeaguePickIconRow() => thisTeamsPickers_leagueUsers : ",this.props.thisTeamsPickers_leagueUsers);}


        return <Container>
            <Row>
                {
                    this && this.props && this.props.myLeagueUsers && this.props.thisTeamsPickers_leagueUsers && this.props.thisTeamsPickers_leagueUsers.length && this.props.thisTeamsPickers_leagueUsers.length > 0 ?
                    this.props.thisTeamsPickers_leagueUsers.map((thisLeagueUserObj)=>{
                        const currentLeagueUserObj = this.props.myLeagueUsers.filter((thisLeagueUser)=>{return thisLeagueUser.id === thisLeagueUserObj.userId})[0]
                        return <Col className="leagueReportUserIconPadding">                            {
                                currentLeagueUserObj && currentLeagueUserObj.icon ?
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props)=>{
                                        return <Tooltip {...props} id="button-tooltip">
                                            {  currentLeagueUserObj && currentLeagueUserObj.name ? currentLeagueUserObj.name : ""}
                                        </Tooltip>
                                    }}
                                >
                                    <Image
                                        //fluid={true} 
                                        className={"image"} 
                                        style={{"height":"40px"}}
                                        roundedCircle 
                                        src={`data:image/png;base64,${currentLeagueUserObj.icon}`} 
                                    /> 
                                </OverlayTrigger> : null
                            }                            
                        </Col>
                    }) : null
                }
            </Row>
        </Container>

    }

}