//import {IVaderWinLossEvent,IVaderWinLoss,IVaderUser} from '../interfaces/vader' ;
//import {IEspnEvent} from '../interfaces/espn' ;

export namespace PickEm_Utilities{
    
    export const onlyUnique = (value:any, index:any, self:any) => { // Return only unique values from a simple array
        return self.indexOf(value) === index ;
    };


    export const queryMyUser = async(email:string,debug:boolean) => {
        if(debug){vaderDebug.myNetworkQuery("utilities.js",`Utilities.queryMyUser() => [input] email : ${email}`);}
    
        if(email){
            return await fetch(
            `/getMyUser?email=${email}`
            ).then((res) => res.json())
            .then(async(json) => {        
            return (json && json[0] ? json[0] : null );
            });
        }
    }

    export const queryMyLeagueUsers = async(leagueId:string,debug:boolean) => {
        if(debug){vaderDebug.myNetworkQuery("utilities.js",`Utilities.queryMyLeagueUsers() => [input] leagueId : ${leagueId}`);}
    
        if(leagueId){
            return await fetch(
            `/getMyLeagueUsers?leagueId=${leagueId}`
            ).then((res) => res.json())
            .then(async(json) => {        
            return (json  ? Object.values(json) : null );
            });
        }
    }
    
    export const queryMyPicks = async(week:number,year:number,seasonType:number,userId:number,debug:boolean) => {
        if(debug){vaderDebug.myNetworkQuery("utilities.js",`getMyPicks => week : ${week}`);}
        if(week && year){
            return await fetch(
            `/getThisWeeksPicks?week=${week}&year=${year}&userId=${userId}&seasonType=${seasonType}`
            ).then((res) => res.json())
            .then(async(json) => {
            return(json ? Object.values(json) : null);  
            });
        }
    }
    
    export const queryMyLeaguePicks = async(week:number,year:number,seasonType:number,debug:boolean,leagueId:string) => {
        if(debug){vaderDebug.myNetworkQuery("utilities.js","queryMyLeaguePicks => week",week);}
        if(debug){vaderDebug.myNetworkQuery("utilities.js","queryMyLeaguePicks => year",year);}
        if(debug){vaderDebug.myNetworkQuery("utilities.js","queryMyLeaguePicks => leagueId",leagueId);}
        
        return await fetch(
        `/getThisLeagueWeeksPicks?week=${week}&year=${year}&leagueId=${leagueId}&seasonType=${seasonType}`
        ).then((res) => res.json()).then((json) => {
        if(debug){vaderDebug.myNetworkQuery("utilities.js",`queryMyLeaguePicks - week : ${week} => Response JSON : `, json);}    
        return(json ? Object.values(json) : null)    
        });
    };
    
    export const updateMyPick = async(pickId:number,userId:number,leagueId:number,pickTeamId:number,/*thisGameObj:IEspnEvent*/eventId:number,pickWeek:number,pickYear:number,seasonType:number,debug:boolean) => {
        //const eventId = thisGameObj && thisGameObj.id ? thisGameObj.id : null ;
        //const pickWeek = thisGameObj && thisGameObj.week && thisGameObj.week.number ? thisGameObj.week.number : null
        //const pickYear =  thisGameObj && thisGameObj.season && thisGameObj.season.year ? thisGameObj.season.year : null
    
        if(debug){vaderDebug.myNetworkQuery("utilities.js",`updateMyPick => pickWeek : ${pickWeek}`);}
        if(pickWeek && pickYear){
            return await fetch(
            `/updatePick?pickId=${pickId}&userId=${userId}&leagueId=${leagueId}&pickTeamId=${pickTeamId}&eventId=${eventId}&week=${pickWeek}&year=${pickYear}&seasonType=${seasonType}`
            ).then((res) => res.json())
            .then(async(json) => {
            return json;
            });
        }
    }
    
    export const newPick = async(userId:number,leagueId:number,pickTeamId:number,/*thisGameObj:IEspnEvent*/eventId:number,pickWeek:number,pickYear:number,seasonType:number,debug:boolean) => {
        //const eventId = thisGameObj && thisGameObj.id ? thisGameObj.id : null ;
        //const pickWeek = thisGameObj && thisGameObj.week && thisGameObj.week.number ? thisGameObj.week.number : null
        //const pickYear =  thisGameObj && thisGameObj.season && thisGameObj.season.year ? thisGameObj.season.year : null
        if(debug){vaderDebug.myNetworkQuery("utilities.js",`newPick => pickWeek : ${pickWeek}`);}
        if(pickWeek && pickYear){
            return await fetch(
            `/insertNewPick?userId=${userId}&leagueId=${leagueId}&pickTeamId=${pickTeamId}&eventId=${eventId}&week=${pickWeek}&year=${pickYear}&seasonType=${seasonType}`
            ).then((res) => res.json())
            .then(async(json) => {
            return json;
            });
        }
    }

    export const queryThisWeek = async (regularSeasonByWeekUrl:string,week:string|number,debug:boolean) => {
        if(debug){vaderDebug.externalNetworkQuery("utilities.js",`queryThisWeek => regularSeasonByWeekUrl : ${regularSeasonByWeekUrl}`);}
        if(debug){vaderDebug.externalNetworkQuery("utilities.js",`queryThisWeek => week : ${week}`);}
        
        return fetch(`${regularSeasonByWeekUrl}${week ? week : 1}`)
            .then((res) => res.json())
            .then((json) => {
                if(debug){vaderDebug.externalNetworkQuery("utilities.js",`queryThisWeek - week : ${week} => Response JSON : `, json);}
                
                return(json)
        });
    }
    
    //  From https://gist.github.com/joduplessis/7b3b4340353760e945f972a69e855d11
    // General utils for managing cookies.
    
    export const setCookie = (name:string, val:any, days:number) => {
        const date = new Date();
        const value = val;
    
        // Set it expire in 7 days
        date.setTime(date.getTime() + (days*24*60*60*1000));
        // Set it
        document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
    };
    
    export const getCookie = (name:string)=> {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        
        if (parts.length === 2) {
            return (parts as any).pop().split(";").shift();
        }
        else{
            return null;
        }
    };
    
    export const deleteCookie=(name:string)=>{
        const date = new Date();
    
        // Set it expire in -1 days
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
    
        // Set it
        document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
    }
    
    export const vaderDebug={
        appLifecycle:(title:string,message:string,data:any)=>{
            //https://www.samanthaming.com/tidbits/40-colorful-console-message/
            const titleStyle = [
            'color: blue',
            'background: lightgrey',
            'font-size: 20px',
            //'border: 1px solid red',
            //'text-shadow: 2px 2px black',
            'padding: 5px',
            ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
        
            const textStyle = [
            'color: blue',
            'font-size: 12px',
        
            ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
            
            console.log('%c%s%c%s',titleStyle,`${title}`,textStyle,`${message}`,data); 
        },
    
        appOnStart:(title:string,message:string,data:any)=>{
        //https://www.samanthaming.com/tidbits/40-colorful-console-message/
        const titleStyle = [
            'color: green',
            //'background: yellow',
            'font-size: 20px',
            //'border: 1px solid red',
            //'text-shadow: 2px 2px black',
            'padding: 5px',
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
    
        const textStyle = [
            'color: blue',
            'font-size: 12px',
    
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
        
        console.log('%c%s%c%s',titleStyle,`App Onstart : ${title}`,textStyle,`${message}`,data); 
        },
    
        myNetworkQuery:(title:string,message:string,data?:any)=>{
        //https://www.samanthaming.com/tidbits/40-colorful-console-message/
        const titleStyle = [
            'color: orange',
            'background: grey',
            'font-size: 20px',
            //'border: 1px solid red',
            //'text-shadow: 2px 2px black',
            'padding: 5px',
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
    
        const textStyle = [
            'color: green',
            'font-size: 12px',
    
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
        
        console.log('%c%s%c%s',titleStyle,`SQL query : ${title}`,textStyle,`${message}`,data); 
        },
    
        externalNetworkQuery:(title:string,message:string,data?:any)=>{
        //https://www.samanthaming.com/tidbits/40-colorful-console-message/
        const titleStyle = [
            'color: red',
            'background: grey',
            'font-size: 20px',
            //'border: 1px solid red',
            //'text-shadow: 2px 2px black',
            'padding: 5px',
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
    
        const textStyle = [
            'color: green',
            'font-size: 12px',
    
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
        
        console.log('%c%s%c%s',titleStyle,`External query : ${title}`,textStyle,`${message}`,data); 
        },  
        
        component:(title:string,message:string,data:any)=>{
        //https://www.samanthaming.com/tidbits/40-colorful-console-message/
        const titleStyle = [
            'color: red',
            'background: grey',
            'font-size: 20px',
            //'border: 1px solid red',
            //'text-shadow: 2px 2px black',
            'padding: 5px',
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
    
        const textStyle = [
            'color: green',
            'font-size: 12px',
    
        ].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)
        
        console.log('%c%s%c%s',titleStyle,`Component - ${title}`,textStyle,`${message}`,data); 
        },
    }
    
    //export {queryThisWeek,deleteCookie,getCookie,setCookie,newPick,updateMyPick,queryMyLeaguePicks,queryMyPicks,queryMyUser}
}